import {
    SET_NEW_MESSAGE,
    SET_NEW_ONLINE_STATUS_CHANGE,
} from '../actions/types';

const initialState = {
    newMessage: null,
    newOnlineStatusChange: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_NEW_MESSAGE:
            return {
                ...state,
                newMessage: payload,
            }
        case SET_NEW_ONLINE_STATUS_CHANGE:
            return {
                ...state,
                newOnlineStatusChange: payload,
            }
        default:
            return state;
    }
};
