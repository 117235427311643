import { useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from 'react-custom-scrollbars';
import { FiChevronLeft, FiSend } from "react-icons/fi";
import { connect } from "react-redux";
import { SpinnerCircular } from 'spinners-react';

import { P1, P2, P3, H1, H2, H3, H4, H5, H6 } from "../../../components/Typography";
import { HBox, VBox } from "../../../components/Containers";
import { IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getDate, getTimeFromDateTime } from "../../../utils";
import { setNewMessage } from "../../../services/actions/chatAction";
import { localstorage } from "../../../config/strings";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const THBox = styled(HBox)`
    height: 50px;
    width: 100%;
    background-color: ${colors.lightGrey};
    // border-bottom: 2px solid ${colors.grey};
    // z-index: 1;
`

const Image = styled.img`
    height: 35px;
    width: 35px;
    border-radius: 20px;
    border: 1px solid ${colors.grey};
`

const Container = styled(VBox)`
    height: calc(100vh - 150px);
`

const ThreadContainer = styled(VBox)`
    width: 100%;
    flex-direction: column-reverse;
`

const MessagesInputContainer = styled(HBox)`
    width: 100%;
    height: 50px;
    background-color: ${colors.lightGrey};
    border: 1px solid ${colors.grey};
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
`

const Input = styled.input`
    font-size: 1rem;
    border-radius: 1.25em;
    width: calc(100% - 64px);
    height: 2.2em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    // ::placeholder {
    //     color: ${colors.grey};
    // }
    :focus {
        outline: 1px solid ${colors.grey};
    }
`

const SendIcon = styled(FiSend)`
    color: ${colors.red};
    cursor: pointer;
    height: auto;
    width: 24px;
`

const MessageCardContainer = styled(VBox)`
    width: max-content;
    max-width: calc(100% - 32px);
    background-color: ${props => props.self ? colors.lightBlue : colors.grey};
    border-radius: 5px;
    align-self: ${props => props.self ? "flex-end" : "flex-start"};
`

// const DateContainer = styled(VBox)`
//     width: max-content;
//     align-self: center;
//     background-color: white;
//     border: 1px solid ${colors.grey};
//     border-radius: 10px;
// `

const MessageCard = ({ text, owner, time, date }) => {
    const [isShowTime, setIsShowTime] = useState(false);
    let textLst = text.split('\n');

    return (
        <MessageCardContainer
            className="p-1 mb-2 clickable"
            self={owner === 'You' ? true : false}
            onClick={() => setIsShowTime(!isShowTime)}
        > 
            {textLst.map((text, index) => (
                <P1 color='black' className="mr-2">{text}</P1>
            ))}
            {isShowTime && <P3 className="mt-1" style={{ alignSelf: 'flex-end' }}>{time} on {date}</P3>}
        </MessageCardContainer>
    )
}

const MessageThread = ({ inboxIndex, setInboxIndex, newMessage, setNewMessage, initInboxes, inboxes, setInboxes }) => {
    const pageSize = 10;
    const scrollRef = useRef(null);
    const [otherUser, setOtherUser] = useState({
        id: null,
        name: "",
        image: null,
        type: ""
    });
    const [messages, setMessages] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [newMessageSelf, setNewMessageSelf] = useState("");
    const [sendDisabled, setSendDisabled] = useState(false);
    // let prevDate = "";
    // let currDate = "";

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadMessages, setLoadMessages] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (inboxIndex !== null) {
            initMessages();
        }
    }, [inboxIndex]);
    useEffect(() => {
        scrollRef.current.scrollToBottom();
    }, [messages]);
    useEffect(() => {
        if (loadMessages) getMessages();
    }, [loadMessages]);
    useEffect(() => {
        if (newMessage) {
            if (newMessage.inbox_id === inboxes[inboxIndex].id) {
                setMessages([newMessage, ...messages]);

                let values = [...inboxes];
                values[inboxIndex] = {...values[inboxIndex], last_message: trimMessage(newMessage.text), last_sent_name: newMessage.owner_name};
                setInboxes(values);
            }
            else {
                initInboxes();
            }
            setNewMessage(null);
        }
        
    }, [newMessage]);

    const initMessages = () => {
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setMessages([]);
        setLoadMessages(true);
    }

    function getMessages() {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'chat/message-list/',
            params: {
                inbox_id: inboxes[inboxIndex]?.id,
                offset: offset,
                limit: limit
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        }).then(res => {
            setIsLoading(false);
            setLoadMessages(false);
            if (res.status === 200) {
                setOtherUser(res.data.other_users[0]);

                setMessages([...messages].concat(res.data.messages));
                setHasMore(res.data.has_more);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
                
                setNewMessage(null);
            }
            else {
                console.log('MESSAGE LIST FETCH FAILED');
            }
        }).catch(error => {
            setIsLoading(false);
            setLoadMessages(false);
            console.log('MESSAGE LIST FETCH ERROR', error);
        });
    }

    function handleEnterPress(e) {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }

    function handleSendMessage() {
        if(validate()) {
            setSendDisabled(true);
            axios({
                method: 'POST',
                url: 'chat/message-list/',
                data: {
                    inbox_id: inboxes[inboxIndex]?.id,
                    text: newMessageSelf,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
                }
            }).then(res => {
                setSendDisabled(false);
                if (res.status === 201) {
                    setMessages([res.data, ...messages]);
                    setNewMessageSelf("");

                    let values = [...inboxes];
                    values[inboxIndex] = {...values[inboxIndex], last_message: trimMessage(res.data.text), last_sent_name: res.data.owner_name};
                    setInboxes(values);
                }
                else {
                    console.log('MESSAGE POST FAILED');
                }
            }).catch(error => {
                setSendDisabled(false);
                console.log('MESSAGE POST ERROR', error);
            });
        }
    }

    function validate() {
        let ok = true;
        if (newMessageSelf.trim() === "") {
            ok = false;
        }

        return ok;
    }

    // function setCurrDate(date) {
    //     currDate = date;
    // }

    // function updatePrevDate() {
    //     prevDate = currDate;
    // }

    const trimMessage = (text) => {
        if(text?.length > 10) {
            return text.slice(0, 10) + '...';
        }
        else {
            return text;
        }
    }

    const handleFetchMessages = () => {
        if (hasMore & !isLoading) {
            getMessages();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop - pad) / (scrollHeight - clientHeight));
        if (t < 0) handleFetchMessages();
    }

    return (
        <Container style={{ width: isMobile ? "100%" : "60%", height: isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 150px)' }}>
            {isMobile && <THBox align='center'>
                <FiChevronLeft
                    size={24}
                    className="ml-2 clickable"
                    onClick={() => setInboxIndex(null)}
                />
                <Image
                    className="ml-2"
                    src={`${baseURL}${otherUser.image}`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="/images/noImage.svg";
                    }}
                />
                <P1 className="ml-1">{otherUser.name}</P1>
                <P2 className="ml-1" color='second'>{otherUser.type}</P2>
            </THBox>}
            <Scrollbars
                style={{ height: isMobile ? 'calc(100% - 100px)' : 'calc(100% - 60px)',
                         width: '100%',
                         backgroundColor: colors.lightGrey,
                         border: `1px solid ${colors.grey}`}}
                ref={scrollRef}
                onUpdate={handleScrollUpdate}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <ThreadContainer className='p-3'>
                    {messages.map((message, index) => (
                        <>
                            {/* {setCurrDate(getDate(message.created_at))} */}
                            {/* {currDate !== prevDate && <DateContainer className='p-1 mb-4 mt-2'>
                                <P2>{currDate}</P2>
                            </DateContainer>} */}
                            <MessageCard
                                text={message.text}
                                owner={message.owner_name}
                                time={getTimeFromDateTime(message.created_at)}
                                date={getDate(message.created_at, 'long')}
                            />
                            {/* {updatePrevDate()} */}
                        </>
                    ))}
                </ThreadContainer>
            </Scrollbars>
            <MessagesInputContainer
                align="center"
                style={{ borderRadius: isMobile ? '0px' : '0px 0px 10px 10px' }}
            >
                <Input
                    className="ml-2 p-1"
                    placeholder="Write your message here..."
                    value={newMessageSelf}
                    onChange={(e) => setNewMessageSelf(e.target.value)}
                    onKeyDown={handleEnterPress}
                />
                {!sendDisabled ?
                    <SendIcon
                        className="ml-1 mr-2"
                        onClick={handleSendMessage}
                        disabled={sendDisabled}
                    />
                    :
                    <SpinnerCircular
                        className="ml-1 mr-2"
                        size={24}
                        thickness={120}
                        speed={100}
                        color={colors.red}
                        secondaryColor={colors.lightGrey}
                    />
                }
            </MessagesInputContainer>
        </Container>
    )
}

const mapStateToProps = state => ({
    newMessage: state.chat.newMessage,
});

export default connect(mapStateToProps, { setNewMessage })(MessageThread);