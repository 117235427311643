import styled from 'styled-components';

import { HBox } from './Containers';
import colors from "../config/colors";

export const PatchLeft = styled(HBox)`
    height: 60px;
    width: 20%;
    position: fixed;
    top: 50px;
    background-color: ${colors.lightGrey};
`

export const PatchRight = styled(HBox)`
    height: 60px;
    width: 20%;
    position: fixed;
    top: 50px;
    right: 0px;
    background-color: ${colors.lightGrey};
`