import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { Scrollbars } from 'react-custom-scrollbars';

import { P1, P2, P3, H1, H2, H3, H4, H5, H6 } from "../../../components/Typography";
import { HBox, VBox } from "../../../components/Containers";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled(VBox)`
    height: calc(100vh - 150px);
`

const Input = styled.input`
    border-radius: 0px  1rem 1rem 0px;
    height: 2rem;
    background-color: ${colors.lightGrey};
    border: 1px solid ${colors.grey};
    border-left: none;
    color: ${colors.darkGrey};
    // ::placeholder {
    //     color: ${colors.grey};
    // }
    :focus {
        outline: none;
    }
`

const SearchIconContainer = styled(HBox)`
    border-radius: 1rem 0px 0px 1rem;
    height: 2rem;
    background-color: ${colors.lightGrey};
    border: 1px solid ${colors.grey};
    border-right: none;
`

const Image = styled.img`
    height: 45px;
    width: 45px;
    border-radius: 30px;
    border: 1px solid ${colors.grey};
`

const InboxCardContainer = styled(HBox)`
    width: 100%;
    box-shadow: ${props => props.selected ? `0px 1px 0px 0px ${colors.shadow}` : 'none' };
    border-radius: 10px;
    border: ${props => props.selected ? `1px solid ${colors.grey}` : 'none'};
    background-color: ${props => props.selected ? colors.lightGrey : 'none'};
`

const SearchBox = ({ className, value, onChange }) => {
    return (
        <HBox className={className}>
            <SearchIconContainer className="px-1" align="center" justify="center">
                <FiSearch />
            </SearchIconContainer>
            <Input
                placeholder="Search for name..."
                value={value}
                onChange={onChange}
            />
        </HBox>
    )
}

const InboxCard = ({index, id, selectedInboxIndex, setSelectedInboxIndex, image, contactName,
                    lastSentUser, lastMessage, seen=false, onClick}) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const trimMessage = (text) => {
        if(text?.length > 10) {
            return text.slice(0, 10) + '...';
        }
        else {
            return text;
        }
    }

    return (
        <InboxCardContainer selected={index===selectedInboxIndex} className="p-1 mb-1 clickable" onClick={() => onClick(index, id)}>
            <Image
                src={`${baseURL}${image}`}
                style={{ height: isMobile ? 60 : 45, width: isMobile ? 60 : 45 }}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src="/images/noImage.svg";
                }}
            />
            <VBox className="ml-1" justify="center">
                {isMobile ?
                    <>
                    <H3 className={`${seen ? '' : 'bold'}`}>{contactName}</H3>
                    <P1 className={`${seen ? '' : 'bold'}`}>{lastSentUser === "You" ? lastSentUser+" : " : ""}{trimMessage(lastMessage)}</P1>
                    </>
                    :
                    <>
                    <P2 className={`${seen ? '' : 'bold'}`}>{contactName}</P2>
                    <P3 className={`${seen ? '' : 'bold'}`}>{lastSentUser === "You" ? lastSentUser+" : " : ""}{trimMessage(lastMessage)}</P3>
                    </>
                }
                
                
            </VBox>
        </InboxCardContainer>
    )
}

const InboxList = ({ inboxes, setInboxes, selectedInboxIndex, setSelectedInboxIndex,
                     searchKey, handleSearch, handleScrollUpdate }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);
    
    const handleSelect = (index) => {
        setSelectedInboxIndex(index);

        let values = [...inboxes];
        values[index].is_seen = true;
        setInboxes(values);
    }

    return (
        <Container align="center" style={{ width: isMobile ? '100%' : '20%',
                                           position: isMobile ? 'relative' : 'static',
                                           top: isMobile ? 60 : 0 }}>
            <SearchBox
                className="my-3"
                value={searchKey}
                onChange={(e) => handleSearch(e.target.value)}
            />
            <Scrollbars
                style={{ height: 'calc(100vh - 230px)' }}
                onUpdate={handleScrollUpdate}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>}
            >
                <VBox className="px-2">
                    {inboxes.map((inbox, index) => (
                        <InboxCard
                            index={index}
                            id={inbox.id}
                            selectedInboxIndex={selectedInboxIndex}
                            setSelectedInboxIndex={setSelectedInboxIndex}
                            contactName={inbox.other_user.name}
                            image={inbox.other_user.image}
                            lastSentUser={inbox.last_sent_name}
                            lastMessage={inbox.last_message}
                            seen={inbox.is_seen}
                            onClick={() => handleSelect(index)}
                        />
                    ))}
                </VBox>
                
            </Scrollbars>
        </Container>
    )
}

export default InboxList;