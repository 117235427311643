import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import { Dialog, Input, Tooltip } from '@mui/material';
import { FiX, FiXCircle, FiPlus, FiEdit } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars';

import { HBox, VBox } from "../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { Button, IconButton } from "../components/Buttons";
import { InputText } from "../components/InputText";
import colors from "../config/colors";
import responsive from "../config/responsive";
import { getDate } from "../utils";
import { rxInst1, rxInst2 } from "../data";
import { localstorage } from '../config/strings';
import { genderData } from '../data';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const Header = styled(HBox)`
    background-color: ${colors.lightGrey};
`

const DeleteIcon = styled(FiXCircle)`
    color: ${colors.red};
    cursor: pointer;
`

const EditIcon = styled(FiEdit)`
    color: ${colors.blue};
    cursor: pointer;
`

const TxtBtn = styled(P2)`
    text-decoration: underline;
    cursor: pointer;
`

const Select = styled.select`
    /* border-radius: 5px; */
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    ::placeholder {
        color: ${colors.grey};
    }
    :focus {
        outline: 1px solid ${colors.blue};
    }
`

const EditIconComponent = forwardRef(function (props, ref) {  // As tooltip is not working on react icons
    // Spread the props to the underlying DOM element.
    return <div {...props} ref={ref}>
              <EditIcon />
           </div>
});

const EditDlg = ({ prescriptionId, open, setOpen, val, setVal, fieldName }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [value, setValue] = useState(val);
    const [valueError, setValueError] = useState("");

    useEffect(() => {
        if (open) {
            setValue(val);
            setValueError("");
        }
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const submit = () => {
        if (validate()) {
            enqueueSnackbar(`Saving patient's ${fieldName}...`, { persist: true });
            axios({
                method: 'PUT',
                url: `doctor/patient-ow/`,
                data: {
                    prescription_id: prescriptionId,
                    field: fieldName,
                    value: value
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
                }
            })
            .then((response) => {
                closeSnackbar();
                if (response.status === 200) {
                    setOpen(false);
                    setVal(value);
                    enqueueSnackbar("Success.", { variant: 'success' });
                } else {
                    enqueueSnackbar(`Patient ${fieldName} save failed!`, { variant: 'error' });
                    console.log('PATIENT FIELD PUT FAILED', response.status);
                }
            })
            .catch((error) => {
                closeSnackbar();
                enqueueSnackbar("Patient name save error!", { variant: 'error' });
                console.log('PATIENT FIELD PUT ERROR', error);
            })
        }
    }

    const validate = () => {
        let ok = true;

        if (value.trim() == '') {
            setValueError('This field cannot be kept blank.');
            ok = false;
        }
        else {
            setValueError("");
        }

        return ok;
    }

    return (
        <Dialog open={open} maxWidth='xs' fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Update Patient's {fieldName}</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                {(() => {
                    if (fieldName === 'gender') {
                        return (
                            <Select onChange={e => setValue(e.target.value)}>
                                {genderData.map((gender, index) => (
                                    <option value={gender} selected={value === gender}>{gender}</option>
                                ))}
                            </Select>
                        );
                    }
                    else {
                        return (
                        <InputText
                            value={value}
                            helpText={valueError}
                            onChange={(e) => setValue(e.target.value)}
                        />);
                    }
                })()}
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={submit}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

const CreatePrescriptionDlg = ({ open, setOpen, prescriptionId }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [appointDetails, setAppointDetails] = useState({
        patient_id: "", patient_name: "", patient_gender: "", patient_age: "", type: "", created_at: "", edited_at: ""
    });
    const [date, setDate] = useState("");

    const [chiefComplaints, setChiefComplaints] = useState([
        {  text: "" },
    ]);
    const [examinations, setExaminations] = useState([
        { text: "" },
    ]);
    const [diagnoses, setDiagnoses] = useState([
        { text: "" },
    ]);
    const [investigations, setInvestigations] = useState([
        { text: "" },
    ]);
    const [suggestedInvestigations, setSuggestedInvestigations] = useState([]);
    const [rxs, setRxs] = useState([
        { drug_name: "", instruction1: "", instruction2: "" },
    ]);
    const [suggestedDrugs, setSuggestedDrugs] = useState([]);
    const [advices, setAdvices] = useState([
        { text: "" },
    ]);
    const [followUp, setFollowUp] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [patientName, setPatientName] = useState("");
    const [patientAge, setPatientAge] = useState("");
    const [patientGender, setPatientGender] = useState("");

    const [openEditName, setOpenEditName] = useState(false);
    const [openEditAge, setOpenEditAge] = useState(false);
    const [openEditGender, setOpenEditGender] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) getData();
    }, [open]);

    const getData = async () => {
        setIsLoading(true);
        enqueueSnackbar("Loading...", { persist: true });
        await axios({
            method: 'GET',
            url: `doctor/prescription-details/`,
            params: {
                prescription_id: prescriptionId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDate(getDate(response.data.prescription.edited_at, 'medium'));
                setAppointDetails(response.data.appointment);
                setPatientName(response.data.appointment.patient_name);
                setPatientAge(response.data.appointment.patient_age);
                setPatientGender(response.data.appointment.patient_gender);
                setChiefComplaints(response.data.prescription.chief_complaints.length ? response.data.prescription.chief_complaints : [{ text: "" }]);
                setExaminations(response.data.prescription.examinations.length ? response.data.prescription.examinations : [{ text: "" }]);
                setDiagnoses(response.data.prescription.diagnoses.length ? response.data.prescription.diagnoses : [{ text: "" }]);
                setInvestigations(response.data.prescription.investigations.length ? response.data.prescription.investigations : [{ text: "" }]);
                setRxs(response.data.prescription.rxs.length ? response.data.prescription.rxs : [{ drug_name: "", instruction1: "", instruction2: "" }]);
                setAdvices(response.data.prescription.advices.length ? response.data.prescription.advices : [{ text: "" }]);
                setFollowUp(response.data.prescription.follow_up);
            } else {
                console.log('PRESCRIPTION GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PRESCRIPTION GET ERROR', error);
        })
    }

    // ************ Change *******************
    const handleChangeChiefCompl = (index, value) => {
        var values = [...chiefComplaints];
        values[index].text = value;
        setChiefComplaints(values);
    }
    const handleChangeExam = (index, value) => {
        var values = [...examinations];
        values[index].text = value;
        setExaminations(values);
    }
    const handleChangeDiag = (index, value) => {
        var values = [...diagnoses];
        values[index].text = value;
        setDiagnoses(values);
    }
    const handleChangeInves = (index, value) => {
        var values = [...investigations];
        values[index].text = value;
        setInvestigations(values);
    }
    const handleChangeRx = (index, value, key) => {
        var values = [...rxs];
        values[index][key] = value;
        setRxs(values);

        setSuggestedDrugs([]);
    }
    const handleChangeAdvice = (index, value) => {
        var values = [...advices];
        values[index].text = value;
        setAdvices(values);
    }
    // *********** End Change ****************

    // ************ Delete *******************
    const handleDeleteChiefCompl = (index) => {
        var values = [...chiefComplaints];
        values.splice(index, 1);
        setChiefComplaints(values);
    }
    const handleDeleteExam = (index) => {
        var values = [...examinations];
        values.splice(index, 1);
        setExaminations(values);
    }
    const handleDeleteDiag = (index) => {
        var values = [...diagnoses];
        values.splice(index, 1);
        setDiagnoses(values);
    }
    const handleDeleteInves = (index) => {
        var values = [...investigations];
        values.splice(index, 1);
        setInvestigations(values);
    }
    const handleDeleteRx = (index) => {
        var values = [...rxs];
        values.splice(index, 1);
        setRxs(values);
    }
    const handleDeleteAdvice = (index) => {
        var values = [...advices];
        values.splice(index, 1);
        setAdvices(values);
    }
    // ********** End Delete ***************

    const handleChangeDrugName = (index, drugName) => {
        handleChangeRx(index, drugName, "drug_name");

        axios({
            method: 'GET',
            url: `doctor/suggested-drug-list/`,
            params: {
                key: drugName
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setSuggestedDrugs(response.data);
            } else {
                console.log('SUGGESTED DRUGS GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('SUGGESTED DRUGS GET ERROR', error);
        })
    }

    const handleChangeInvesSugg = (index, value) => {
        handleChangeInves(index, value);

        axios({
            method: 'GET',
            url: `doctor/suggested-diagnostic-list/`,
            params: {
                key: value
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setSuggestedInvestigations(response.data);
            } else {
                console.log('SUGGESTED DIAGNOSTIC GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('SUGGESTED DIAGNOSTIC GET ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const submit = () => {
        if (validate()) {
            setIsLoading(true);
            enqueueSnackbar("Prescription saving...", { persist: true });
            axios({
                method: 'PUT',
                url: `doctor/prescription-details/`,
                data: {
                    prescription_id: prescriptionId,
                    chief_complaints: chiefComplaints,
                    examinations: examinations,
                    diagnoses: diagnoses,
                    investigations: investigations,
                    rxs: rxs,
                    advices: advices,
                    follow_up: followUp
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
                }
            })
            .then((response) => {
                setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    setOpen(false);
                    enqueueSnackbar("Success.", { variant: 'success' });
                } else {
                    enqueueSnackbar("Prescription save failed!", { variant: 'error' });
                    console.log('PRESCRIPTION PUT FAILED', response.status);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                closeSnackbar();
                enqueueSnackbar("Prescription save error!", { variant: 'error' });
                console.log('PRESCRIPTION PUT ERROR', error);
            })
        }
    }

    const validate = () => {
        return true
    }

    const getShortDosageDescription = (dosageDesc) => {
        if (dosageDesc.toLowerCase().includes('tablet')) {
            return "Tab. ";
        }
        else if (dosageDesc.toLowerCase().includes('capsule')) {
            return "Cap. ";
        }
        else if (dosageDesc.toLowerCase().includes('syrup')) {
            return "Syr. ";
        }
        else if (dosageDesc.toLowerCase().includes('suspension')) {
            return "Susp. ";
        }
        else if (dosageDesc.toLowerCase().includes('suppository')) {
            return "Supp. ";
        }
        else if (dosageDesc.toLowerCase().includes('pediatric drops')) {
            return "PD ";
        }
        else if (dosageDesc.includes('IV')) {
            return "IV ";
        }
        else if (dosageDesc.includes('IM')) {
            return "IM ";
        }
        else {
            return "";
        }
    }

    return (
        <Dialog
            maxWidth={false}
            fullScreen={isMobile ? true : false}
            scroll='body'
            open={open}
            onClose={handleClose}
            style={{  }}
            fullWidth
        >
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.blue }}>
                <H4 className='ml-2' color='white'>Manage Prescription</H4>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Header justify="space-between" className="p-2">
                <HBox>
                    <P2 color='first'>Name of Patient:</P2>
                    <H5 className="ml-0_5">{patientName}</H5>
                    <Tooltip title='Edit' arrow>
                        <EditIconComponent
                            style={{ marginLeft: 2 }}
                            onClick={() => setOpenEditName(true)}
                        />
                    </Tooltip>
                    <P2 className='ml-1' color='first'>Pt. ID:</P2>
                    <H5 className='ml-0_5'>{appointDetails.patient_id}</H5>
                </HBox>
                <HBox align='center'>
                    <P2 color='third'>Gender:</P2>
                    <H5 className="ml-0_5">{patientGender}</H5>
                    <Tooltip title='Edit' arrow>
                        <EditIconComponent
                            style={{ marginLeft: 2 }}
                            onClick={() => setOpenEditGender(true)}
                        />
                    </Tooltip>
                    <P2 className='ml-1' color='third'>Age:</P2>
                    <H5 className='ml-0_5'>{patientAge}</H5>
                    <Tooltip title='Edit' arrow>
                        <EditIconComponent
                            style={{ marginLeft: 2 }}
                            onClick={() => setOpenEditAge(true)}
                        />
                    </Tooltip>
                    <P2 className='ml-1' color='third'>Type:</P2>
                    <H5 className='ml-0_5'>{appointDetails.type}</H5>
                </HBox>
                <HBox>
                    <P2 color='second'>Date:</P2>
                    <H5 className="ml-0_5">{date}</H5>
                </HBox>
            </Header>
            <Line />
            {/* <Scrollbars
                style={{ height: '100%' }}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
            }> */}
                <VBox className="p-2">
                    <HBox justify="flex-start" align="flex-end">
                        <H5>Chief Complain</H5>  {/* Changed from complaints according to Emtiaz vaia */}
                        {/* <TxtBtn color="first">Presets</TxtBtn> */}
                    </HBox>
                    {chiefComplaints.map((chiefComplInd, index) => (
                        <HBox align="center" className={isMobile ? 'mb-1': ''}>
                            <InputText
                                value={chiefComplInd.text}
                                style={{ width: isMobile ? '90%' : '96%' }}
                                onChange={e => handleChangeChiefCompl(index, e.target.value)}
                                onKeyDown={e => {if (e.key === 'Enter') setChiefComplaints([...chiefComplaints, { text: "" }])}}
                            />
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteChiefCompl(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setChiefComplaints([...chiefComplaints, { text: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox>

                    <H5 className='mt-4'>Examination</H5>
                    {examinations.map((examInd, index) => (
                        <HBox align="center" className={isMobile ? 'mb-1': ''}>
                            <InputText
                                value={examInd.text}
                                style={{ width: isMobile ? '90%' : '96%' }}
                                onChange={e => handleChangeExam(index, e.target.value)}
                                onKeyDown={e => {if (e.key === 'Enter') setExaminations([...examinations, { text: "" }])}}
                            />
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteExam(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setExaminations([...examinations, { text: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox>

                    <H5 className='mt-4'>Diagnosis</H5>
                    {diagnoses.map((diagInd, index) => (
                        <HBox align="center" className={isMobile ? 'mb-1': ''}>
                            <InputText
                                value={diagInd.text}
                                style={{ width: isMobile ? '90%' : '96%' }}
                                onChange={e => handleChangeDiag(index, e.target.value)}
                                onKeyDown={e => {if (e.key === 'Enter') setDiagnoses([...diagnoses, { text: "" }])}}
                            />
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteDiag(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setDiagnoses([...diagnoses, { text: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox>

                    <H5 className='mt-4'>Investigation</H5>
                    {investigations.map((invesInd, index) => (
                        <HBox align="center" className={isMobile ? 'mb-1': ''}>
                            <InputText
                                list='investigations'
                                value={invesInd.text}
                                style={{ width: isMobile ? '90%' : '96%' }}
                                onChange={e => handleChangeInvesSugg(index, e.target.value)}
                                onKeyDown={e => {if (e.key === 'Enter') setInvestigations([...investigations, { text: "" }])}}
                            />
                            <datalist id='investigations'>
                                {suggestedInvestigations.map((inves, index) => {
                                    return <option
                                                key={inves.id}
                                            >{inves.name}</option>
                                })}
                            </datalist>
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteInves(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setInvestigations([...investigations, { text: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox>

                    <H5 className='mt-4'>Rx</H5>
                    {rxs.map((rxInd, index) => (
                        <HBox align="center" className="mb-1">
                            <HBox justify="space-between" style={{ width: isMobile ? '90%' : '96%' }}>
                                <InputText
                                    list='drugs'
                                    placeholder="Drug name"
                                    value={rxInd.drug_name}
                                    style={{ width: isMobile ? '50%' : '32%' }}
                                    className={isMobile ? 'p-0_5' : ''}
                                    onChange={e => handleChangeDrugName(index, e.target.value)}
                                    onKeyDown={e => {if (e.key === 'Enter') setRxs([...rxs, { drug_name: "", instruction1: "", instruction2: "" }])}}
                                />
                                <datalist id='drugs'>
                                    {suggestedDrugs.map((drug, index) => {
                                        return <option
                                                    key={drug.id}
                                                    value={getShortDosageDescription(drug.dosage_description) + drug.brand_name}
                                                    onClick={e => handleChangeRx(index, e.target.value, 'drug_name')}
                                                >{drug.mfg_name} | {drug.generic_name}</option>
                                    })}
                                </datalist>
                                <InputText
                                    placeholder="Ex.: 1 + 0 + 0 After meal"
                                    list='inst1'
                                    value={rxInd.instruction1}
                                    style={{ width: isMobile ? '50%' : '32%' }}
                                    className={isMobile ? 'p-0_5' : ''}
                                    onChange={e => handleChangeRx(index, e.target.value, "instruction1")}
                                    onKeyDown={e => {if (e.key === 'Enter') setRxs([...rxs, { drug_name: "", instruction1: "", instruction2: "" }])}}
                                />
                                <datalist id='inst1'>
                                    {rxInst1.map((inst1, index) => (
                                        <option>{inst1}</option>
                                    ))}
                                </datalist>
                                <InputText
                                    placeholder="Ex.: Continue for 7 days"
                                    list='inst2'
                                    value={rxInd.instruction2}
                                    style={{ width: isMobile ? '50%' : '32%' }}
                                    className={isMobile ? 'p-0_5' : ''}
                                    onChange={e => handleChangeRx(index, e.target.value, "instruction2")}
                                    onKeyDown={e => {if (e.key === 'Enter') setRxs([...rxs, { drug_name: "", instruction1: "", instruction2: "" }])}}
                                />
                                <datalist id='inst2'>
                                    {rxInst2.map((inst2, index) => (
                                        <option>{inst2}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteRx(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setRxs([...rxs, { drug_name: "", instruction1: "", instruction2: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox>

                    <H5 className='mt-4'>Advice</H5>
                    {advices.map((adviceInd, index) => (
                        <HBox align="center" className={isMobile ? 'mb-1': ''}>
                            <InputText
                                value={adviceInd.text}
                                style={{ width: isMobile ? '90%' : '96%' }}
                                onChange={e => handleChangeAdvice(index, e.target.value)}
                                onKeyDown={e => {if (e.key === 'Enter') setAdvices([...advices, { text: "" }])}}
                            />
                            <DeleteIcon
                                className='ml-1'
                                onClick={() => handleDeleteAdvice(index)}
                            />
                        </HBox>
                    ))}
                    <HBox>
                        <Button
                            size='xs'
                            color="third"
                            onClick={() => setAdvices([...advices, { text: "" }])}
                        >
                            <FiPlus className='mr-1' />
                            Add
                        </Button>
                    </HBox> 

                    <H5 className='mb-1 mt-4'>Follow Up</H5>
                    <InputText
                        value={followUp}
                        style={{ width: isMobile ? '90%' : '96%' }}
                        onChange={e => setFollowUp(e.target.value)}
                    />
                </VBox>
            {/* </Scrollbars> */}
            <Line />
            <HBox align="center" justify="flex-end" className="py-1 px-2">
                {/* <TxtBtn color="second">Save As Preset</TxtBtn> */}
                <HBox>
                    <CloseBtn size="sm" className='mr-1' onClick={handleClose}>Close</CloseBtn>
                    <Button size="sm" color="first" onClick={submit}>Save</Button>
                </HBox>
            </HBox>
            
            <EditDlg
                prescriptionId={prescriptionId}
                open={openEditName}
                setOpen={setOpenEditName}
                val={patientName}
                setVal={setPatientName}
                fieldName='name'
            />
            <EditDlg
                prescriptionId={prescriptionId}
                open={openEditAge}
                setOpen={setOpenEditAge}
                val={patientAge}
                setVal={setPatientAge}
                fieldName='age'
            />
            <EditDlg
                prescriptionId={prescriptionId}
                open={openEditGender}
                setOpen={setOpenEditGender}
                val={patientGender}
                setVal={setPatientGender}
                fieldName='gender'
            />
        </Dialog>
    )
}

export default CreatePrescriptionDlg;