import styled from "styled-components";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

import { VBox, HBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { trimText } from "../../../utils";

const Container = styled.div`
    background-color: ${colors.veryLightGreen};
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.green};
    border-radius: 10px;
    /* box-shadow: 0px 1px 0px 0px ${colors.shadow}; */
    contain: content;

    :hover {
        box-shadow: 1px 1px 8px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 85%;
        height: auto;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 40%;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35%;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 25%;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 23%;
    }
`

const ColorBar = styled.div`
    height: 4px;
    width: 40%;
    border-radius: 0px 0px 4px 4px;
    background-color: ${colors.green};
`

const MailIcon = styled(FiMail)`
    color: ${colors.darkGrey};
`

const Count = styled(P2)`
    padding: 2px;
    margin-left: 4px;
    color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 4px;
`

const SLine = styled.div`
    width: 85%;
    border-bottom: 0.5px solid ${colors.green};
`

const SHBox = styled(HBox)`
    width: 100%;
`

const SLink = styled(Link)`
    text-decoration: none;
    position: fixed;
    bottom: 24px;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        position: static;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const MessagesCard = ({ messages }) => {
    return (
        <Container className="m-2">
            <ColorBar />
            <MailIcon fontSize={25} className="mt-1 mb-1" />
            <HBox align="center" className="mb-1">
                <H4>Messages</H4>
                <Count>{messages.count}</Count>
            </HBox>
            <SLine className="mb-2" />
            {messages.data ?
                messages.data.map((message, index) => (
                    <>
                    <SHBox justify="space-between" className="pl-4 pr-4">
                        <H5 className="mr-1">{message.other_user?.name}</H5>
                        <P2>{moment(message.edited_at, "YYYY-MM-DDThh:mm:ss").format('hh:mm A')}</P2>
                    </SHBox>
                        <P2></P2>
                    <SHBox className="pl-4 pr-4 mb-2">
                        <P2>{message.last_sent_name==='You' && message.last_sent_name+' : '}{trimText(message.last_message, 80)}</P2>
                    </SHBox>
                    </>
                ))
                :
                <P2>No message.</P2>
            }
            
            <SLink to='/messages'>
                <Button size="sm" color="third" outlined>View All</Button>
            </SLink>
        </Container>
    )
}

export default MessagesCard;