import styled from "styled-components";

import { VBox } from "../components/Containers";
import { P3 } from "../components/Typography";
import colors from "../config/colors";

const Container = styled(VBox)`
    height: 40px;
    width: 100%;
    position: sticky;
    bottom: 0px;
    background-color: ${colors.lightGrey};
    border-top: 1px solid ${colors.grey};
`

const A = styled.a`
    text-decoration: none;
`

const Footer = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };

    return (
        <Container align='center' justify='center'>
            <A href={process.env.REACT_APP_USER_MANUAL_URL} target='_blank'>
                <P3 color="third" className="bold">
                    User Manual
                </P3>
            </A>
            <P3>Copyright © {getYear()} NextGen DigiTech. All rights reserved.</P3>
        </Container>
    )
}

export default Footer;