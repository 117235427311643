import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { FiEdit3, FiCheck, FiX, FiPlusSquare, FiTrash } from "react-icons/fi";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtnActive, NavBtn } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { getDate } from "../../utils";
import { localstorage } from "../../config/strings";

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const MyFees = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appConfig, setAppConfig] = useState({
        fee: null, followup_fee: null
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `doctor/appointment-config-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setAppConfig(response.data);
            } else {
                console.log('APPOINTMENT CONFIG FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('APPOINTMENT CONFIG FETCH ERROR', error);
        })
    }

    const fieldHtml = (name, field, type='text') => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
                <P1>
                    {appConfig[field] ?
                        type==='date' ?
                            getDate(appConfig[field], 'long')
                            :
                            appConfig[field]
                        :
                        'Not Set'
                    }
                </P1>
            </td>
        </tr>
    )

    return (
        <div>
            <LowerNav selected="my-account" />
            <HBox>
                {isMobile && <TopPanel justify='center' className='p-3'>
                    <SLink to='/profile' className="m-0_5">
                        <Button size='sm' color='first' outlined>Profile</Button>
                    </SLink>
                    <Button size='sm' color='first' className="m-0_5">Fees</Button>
                    <SLink to='/password'>
                        <Button size='sm' color='first' className="m-0_5" outlined>Change Password</Button>
                    </SLink>
                </TopPanel>}
                {!isMobile && <LeftPanel>
                    <SLink to="/profile" className="mt-8">
                        <NavBtn size="sm" color="first" outlined>
                            <div className="ml-1">Profile</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Fees</div>
                    </NavBtnActive>
                    <SLink to="/password" className="mt-1">
                        <NavBtn size="sm" color="first" outlined>
                            <div className="ml-1">Change Password</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 150px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className="p-3" style={{ width: '100%' }}>
                            <Paper className="p-4 mb-2">
                                <VBox>
                                    <H3 color="first">Fees</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {fieldHtml('REGULAR FEE', 'fee')}
                                        {fieldHtml('FOLLOW UP FEE', 'followup_fee')}
                                    </table>
                                </VBox>
                            </Paper>
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel></RightPanel>}
            </HBox>
            {!isMobile && <Footer />}
        </div>
    )
}

export default MyFees;