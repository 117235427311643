import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { FiCalendar } from "react-icons/fi";
import { useSnackbar } from 'notistack';
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";

import { HBox, VBox } from "../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button } from "../../components/Buttons";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import AppointmentsCard from "./components/AppointmentsCard";
import MessagesCard from "./components/MessagesCard";
import StatisticsCard from "./components/StatisticsCard";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { PatchLeft, PatchRight } from "../../components/Patches";
import { localstorage } from "../../config/strings";

const Container = styled.div`
    width: 100%;
    position: absolute;
    left: 0px;
    top: 50px;
`

const Header = styled(VBox)`
    height: 60px;
    justify-content: center;
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Body = styled(HBox)`
    height: calc(100vh - 150px);
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Dashboard = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [doctorName, setDoctorName] = useState("");
    const [appointments, setAppointments] = useState({ count: null, data: null });
    const [messages, setMessages] = useState({ count: null, data: null });

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        closeSnackbar();
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    const getData = async () => {
        await axios({
            method: 'GET',
            url: 'doctor/dashboard/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                console.log('DASHBOARD DATA FETCH SUCCESS');

                setDoctorName(response.data.name);
                setAppointments(response.data.appointments);
                setMessages(response.data.messages);
            } else {
                console.log('DASHBOARD DATA FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('DASHBOARD DATA FETCH ERROR', error);
        })
    }

    return (
        <div>
            <LowerNav selected="dashboard" />
            <Container>
                <Header className="pl-2" justify='center'>
                    {/* {!isMobile && <H6 color='second'>WELCOME</H6>}
                    {!isMobile && <H5>{doctorName}</H5>} */}
                </Header>
                <Body>
                    {!isMobile && <PatchLeft />}
                    {!isMobile && <PatchRight />}
                    {isMobile && <TopPanel className='p-2' align='flex-start'>
                        <H5 color='default'><span style={{ color: colors.red }}>Welcome,</span> {doctorName}</H5>
                    </TopPanel>}
                    <Scrollbars
                        style={{ height: '100%', backgroundColor: colors.lightGrey }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        {!isMobile && <HBox justify='center' className='mt-3'>
                            <H2 color='third' className='bold'>Welcome,</H2>
                            <H2 className='bold ml-0_5'>{doctorName}</H2>
                        </HBox>}
                        {!isMobile && <P1 color='default' align='center'>We are happy to ease your patient consultation process.</P1>}
                        <HBox justify='center' className={isMobile ? "" : "p-2"} style={{ flexWrap: 'wrap' }}>
                            <AppointmentsCard isMoble={isMobile} appointments={appointments} />
                            <MessagesCard isMobile={isMobile} messages={messages} />
                            {/* <StatisticsCard isMobile={isMobile} /> */}
                        </HBox>
                    </Scrollbars>
                </Body>
                {!isMobile && <Footer />}
            </Container>
        </div>
    )
}

export default Dashboard;