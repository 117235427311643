import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import { localstorage } from '../../../config/strings';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ChangePasswordDlg = ({open, setOpen, profile, setProfile}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [currentPass, setCurrentPass] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [currentPassError, setCurrentPassError] = useState("");
    const [password1Error, setPassword1Error] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    useEffect(() => {
        const init = () => {
            setCurrentPass("");
            setPassword1("");
            setPassword2("");

            setCurrentPassError("");
            setPassword1Error("");
            setPassword2Error("");
        }
        if (open) init();
    }, [open]);

    const handleChangePass = () => {
        if (validate()) {
            axios({
                method: "PUT",
                url: 'auth/password/',
                data: {
                    old_password: currentPass,
                    password1: password1,
                    password2: password1,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`,
                },
            }).then(function (res) {
                if (res.status === 200) {
                    setProfile({...profile, password_updated_on: res.data.password_updated_on});
                    setOpen(false);

                    enqueueSnackbar('Password updated.', {variant: 'success'});
                }
                else {
                    console.log(res.data.message);
                    enqueueSnackbar(res.data.message, {variant: 'error'});
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    console.log(error.response.data.message);
                    enqueueSnackbar(error.response.data.message, {variant: 'error'});
                }
            });
        }
    }

    const validate = () => {
        var ok = true;
        if (currentPass) {
            setCurrentPassError("");
        }
        else {
            setCurrentPassError("This field cannot be kept blank.");
            ok = false;
        }

        if (password1.length < 8) {
            setPassword1Error("Minimum password length is eight.");
            ok = false;
        }
        else {
            setPassword1Error("");
        }

        if (password1 !== password2) {
            setPassword2Error("Passwords do not match.")
            ok = false;
        }
        else {
            setPassword2Error("");
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Change Password</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <InputText
                    label="Current password"
                    type='password'
                    value={currentPass}
                    helpText={currentPassError}
                    onChange={e => setCurrentPass(e.target.value)}
                    autoFocus={true}
                />
                <VBox className="mt-4">
                    <InputText
                        label="New password"
                        type='password'
                        value={password1}
                        helpText={password1Error}
                        onChange={e => setPassword1(e.target.value)}
                    />
                </VBox>
                <VBox className="mt-1">
                    <InputText
                        label="Confirm new password"
                        type='password'
                        value={password2}
                        helpText={password2Error}
                        onChange={e => setPassword2(e.target.value)}
                    />
                </VBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleChangePass}
                >
                    Change Password
                </Button>
            </HBox>
        </Dialog>
    )
}

export default ChangePasswordDlg;