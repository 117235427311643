import { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from "styled-components";
import { Dialog, Slide, Tooltip } from '@mui/material';
import { FiFileText, FiEye, FiMail, FiMic, FiMicOff, FiVideo, FiVideoOff, FiPhone, FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

import { HBox, VBox } from "../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button, IconButton } from "../../components/Buttons";
import { InputText } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { removeHttp } from "../../utils";
import { setShowUppernav } from '../../services/actions/authAction';
import CreatePrescriptionDlg from '../../layouts/CreatePrescriptionDlg';
import { localstorage } from '../../config/strings';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const DialContainer = styled(VBox)`
    height: calc(100vh - 60px);
    background: linear-gradient(229deg, #94c581 0%, rgb(17, 154, 192) 100%);
`

const Image = styled.img`
    height: 160px;
    width: 160px;
    border-radius: 80px;
    border: 2px solid ${colors.darkGrey};
`

const OtherVideo = styled.video`
`

const MyVideo = styled.video`
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
`

const BottomPanel = styled(HBox)`
    background-color: black;
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.25);
`

const PrescriptionIcon = styled(FiFileText)`
    color: white;
    cursor: pointer;
`

const DetailsIcon = styled(FiEye)`
    color: white;
    cursor: pointer;
`

const MessageIcon = styled(FiMail)`
    color: white;
    cursor: pointer;
`

const MicOnIcon = styled(FiMic)`
    color: ${colors.blue};
`

const MicOffIcon = styled(FiMicOff)`
    color: ${colors.red};
`

const VideoOnIcon = styled(FiVideo)`
    color: ${colors.blue};
`

const VideoOffIcon = styled(FiVideoOff)`
    color: ${colors.red};
`

const CallIcon = styled(FiPhone)`
    color: white;
`

const IconBox = styled(HBox)`
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : 'transparent'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent' };
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

let remoteRTCMessage;
let iceCandidatesFromCaller = [];
let peerConnection;
let remoteStream;
let localStream;
let callSocket;
let resendCall = true;

const VideoCall = ({ setShowUppernav }) => {
    let pcConfig = {
        "iceServers":
            [
                { "url": "stun:nextgenmyhealth.com:3478" },
                {
                    "url": "turn:nextgenmyhealth.com:3478",
                    "username": "nextg",
                    "credential": "123456"
                },
                // { "url": "stun:103.125.253.253:3478" },
                // {
                //     "url": "turn:103.125.253.253:3478",
                //     "username": "nextg",
                //     "credential": "123456"
                // },
                { "url": "stun:openrelay.metered.ca:80" },
                {
                    "url": "turn:openrelay.metered.ca:443",
                    "username": "openrelayproject",
                    "credential": "openrelayproject"
                },
                {"url": "stun:stun.l.google.com:19302"}
            ]
    }

    const { receiverId, prescriptionId } = useParams();
    
    const localVideo = useRef(null);
    const remoteVideo = useRef(null);
    const [otherUser, setOtherUser] = useState({
        name: "", image: ""
    });
    const [windowState, setWindowState] = useState('CALLING')  // CALLING, CONNECTING, INCALL, REJECTED, ENDED, NOTANSWERED
    const [callAck, setCallAck] = useState(false);  // Is call acknowleged?
    const [micOn, setMicOn] = useState(true);
    const [videoOn, setVideoOn] = useState(true);

    // const [isLoading, setIsLoading] = useState(false);
    const [openPres, setOpenPres] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {  // To end call if doctor finishes the consultation
        const onReceiveMessage = (e) => {
            const { key, newValue } = e;
            if (key === localstorage.consultFinishPtUserId) {
                if (newValue == receiverId) handleEndCall();
            }
        };
        
        window.addEventListener("storage", onReceiveMessage);

        return () => {
            window.removeEventListener("storage", onReceiveMessage);
        };
    }, []);

    setShowUppernav(false);
    useEffect(() => {
        getUserData();
        getOtherUserData();
    }, []);

    const getUserData = () => {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        }).then(res => {
            if (res.status === 200) {
                connectSocket(res.data.id);
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    const getOtherUserData = () => {
        axios({
            method: 'GET',
            url: `core/user-details/`,
            params: {
                id: receiverId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setOtherUser({...otherUser, name: response.data.name, image: response.data.image});
                sendPushNotification();
            } else {
                console.log('OTHER USER DETAILS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('OTHER USER DETAILS FETCH ERROR', error);
        })
    }

    const sendPushNotification = () => {
        axios({
            method: 'POST',
            url: `chat/push-notification/`,
            data: {
                receiver_id: receiverId,
                notification_type: 'call.received'
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                console.log('NOTIFICATION SENT', response.status);
            } else {
                console.log('NO DEVICE FOUND', response.status);
            }
        })
        .catch((error) => {
            console.log('FCM ERROR', error);
        })
    }

    function call() {
        setWindowState('CALLING');
        setCallAck(false);
        
        sendCall({
            receiver_id: receiverId,
        })

        resendCall = true;
        let i = 0;
        let maxSendNum = 8;
        function sendCallLoop() {
            setTimeout(function() {
                i++;
                if (i < maxSendNum && resendCall) {
                    sendCall({
                        receiver_id: receiverId,
                    })
                    sendCallLoop();
                }
                if (i === maxSendNum) {
                    handleNotAnswered();
                }
            }, 5000);
        }
        sendCallLoop();
    }

    function inviteVideo() {
        setWindowState('CONNECTING');

        beReady()
            .then(bool => {
                processCall(receiverId)
            })
    }

    function connectSocket(userId) {
        let ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
        
        callSocket = new WebSocket(
            ws_scheme
            + removeHttp(process.env.REACT_APP_SERVER_URL)
            // + window.location.host
            + 'ws/chat/'
        );
        
        callSocket.onopen = event => {
            callSocket.send(JSON.stringify({
                type: 'login',
                data: {
                    id: userId
                }
            }));
        }
    
        callSocket.onmessage = (e) => {
            let response = JSON.parse(e.data);
            let type = response.type;
    
            if(type == 'connection') {
                console.log(response.data.message);
                call();
            }

            if (type == 'call_acknowleged') {
                setCallAck(true);
            }
    
            if(type == 'call_answered') {
                onCallAnswered(response.data);
            }

            if (type == 'vinvite_replied') {
                onVInvitationReplied(response.data);
            }

            if(type == 'call_rejected') {
                onCallRejected(response.data);
            }

            if(type == 'call_ended') {
                onCallEnded(response.data);
            }
    
            if(type == 'ICEcandidate') {
                onICECandidate(response.data);
            }
        }

        const onCallAnswered = (data) => {
            closeDialing({
                receiver_id: receiverId,
            });

            inviteVideo();

            console.log('Call answered.');
        }
    
        const onVInvitationReplied = (data) => {
            //when other accept our call
            remoteRTCMessage = data.rtcMessage;
            peerConnection.setRemoteDescription(new RTCSessionDescription(remoteRTCMessage));
    
            setWindowState('INCALL');
            resendCall = false;
    
            console.log("Video invitation replied.");
        }

        const onCallRejected = (data) => {
            localStream?.getTracks().forEach(track => track.stop());
            peerConnection?.close();
            peerConnection = null;
            setWindowState('REJECTED');
            resendCall = false;

            console.log("Call rejected.");
        }

        const onCallEnded = (data) => {
            endCall();
            setWindowState('ENDED');

            console.log("Call ended.");
        }
    
        const onICECandidate = (data) => {
            console.log("GOT ICE candidate");
    
            let message = data.rtcMessage
    
            let candidate = new RTCIceCandidate({
                sdpMLineIndex: message.label,
                candidate: message.candidate
            });
    
            if (peerConnection) {
                console.log("ICE candidate Added");
                peerConnection.addIceCandidate(candidate);
            } else {
                console.log("ICE candidate Pushed");
                iceCandidatesFromCaller.push(candidate);
            }
    
        }
    }

    function sendCall(data) {
        console.log("Send Call");
        callSocket.send(JSON.stringify({
            type: 'call',
            data
        }));
    }

    function closeDialing(data) {
        console.log("Close dialing");
        callSocket.send(JSON.stringify({
            type: 'close_dialing',
            data
        }));
    }

    function sendVideoInvitation(data) {
        console.log("Send Video Invitation");
        callSocket.send(JSON.stringify({
            type: 'invite_video',
            data
        }));
    }

    function sendICEcandidate(data) {
        //send only if we have caller, else no need to
        console.log("Send ICE candidate");
        callSocket.send(JSON.stringify({
            type: 'ICEcandidate',
            data
        }));
    }

    function beReady() {
        return navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        })
            .then(stream => {
                console.log('Stream...');
                localStream = stream;
                localVideo.current.srcObject = stream;
                
                return createConnectionAndAddStream()
            })
            .catch(function (e) {
                // alert('getUserMedia() error: ' + e.id);
                alert('Unable to reach the media devices. Please check the permissions.');
            });
    }

    function createConnectionAndAddStream() {
        createPeerConnection();
        peerConnection.addStream(localStream);
        return true;
    }

    function processCall(receiverId) {
        peerConnection.createOffer((sessionDescription) => {
            peerConnection.setLocalDescription(sessionDescription);
            sendVideoInvitation({
                receiver_id: receiverId,
                rtcMessage: sessionDescription,
            })
        }, (error) => {
            console.log("Error", error);
        });
    }

    function createPeerConnection() {
        try {
            peerConnection = new RTCPeerConnection(pcConfig);
            peerConnection.onicecandidate = handleIceCandidate;
            peerConnection.onaddstream = handleRemoteStreamAdded;
            peerConnection.onremovestream = handleRemoteStreamRemoved;
            console.log('Created RTCPeerConnnection');
            return;
        } catch (e) {
            console.log('Failed to create PeerConnection, exception: ' + e.message);
            // alert('Cannot create RTCPeerConnection object.');
            alert('Connection creation failed. It appears your current browser might not be supported. Please consider switching to a different browser.');
            return;
        }
    }

    function handleIceCandidate(event) {
        if (event.candidate) {
            console.log("Local ICE candidate");
            // If a srflx candidate was found, notify that the STUN server works!
            if(event.candidate.type == "srflx"){
                console.log("The STUN server is reachable!");
                // console.log(`   Your Public IP Address is: ${event.candidate.address}`);
            }

            // If a relay candidate was found, notify that the TURN server works!
            if(event.candidate.type == "relay"){
                console.log("The TURN server is reachable !");
            }
    
            sendICEcandidate({
                user_id: receiverId,
                rtcMessage: {
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate
                }
            })
    
        } else {
            console.log('End of candidates.');
        }
    }

    function handleRemoteStreamAdded(event) {
        console.log('Remote stream added.');
        remoteStream = event.stream;
        remoteVideo.current.srcObject = remoteStream;
    }
    
    function handleRemoteStreamRemoved(event) {
        console.log('Remote stream removed. Event: ', event);
        remoteVideo.current.srcObject = null;
        localVideo.current.srcObject = null;
    }
    
    window.onbeforeunload = function () {
        if (windowState === 'INCALL' || windowState === 'CALLING' || windowState === 'CONNECTING') {
            handleEndCall();
        }
    }
    
    function handleEndCall() {
        endCall();
        callSocket.send(JSON.stringify({
            type: 'end_call',
            data: {
                user_id: receiverId
            }
        }));

        setWindowState('ENDED');
    }

    function handleNotAnswered() {
        endCall();
        callSocket.send(JSON.stringify({
            type: 'end_call',
            data: {
                user_id: receiverId
            }
        }));

        setWindowState('NOTANSWERED');
    }
    
    function endCall() {
        localStream?.getTracks().forEach(track => track.stop());
        peerConnection?.close();
        peerConnection = null;
        resendCall = false;
    }

    const handleClickMic = () => {
        const audioTrack = localStream?.getTracks().find(track => track.kind === 'audio');

        if(audioTrack) {
            if(audioTrack.enabled) {
                audioTrack.enabled = false;
                setMicOn(false);
            }
            else {
                audioTrack.enabled = true;
                setMicOn(true);
            }
        }
    }

    const handleClickVideo = () => {
        const videoTrack = localStream?.getTracks().find(track => track.kind === 'video');

        if(videoTrack) {
            if(videoTrack.enabled) {
                videoTrack.enabled = false;
                setVideoOn(false);
            }
            else {
                videoTrack.enabled = true;
                setVideoOn(true);
            }
        }
    }

    return (
        <VBox>
            {windowState === 'CALLING' && <DialContainer
                justify="center"
                align="center"
            >
                <H2 color="black" className='normal'>{callAck ? 'Ringing...' : 'Calling...'}</H2>
                {otherUser.image && <Image
                                        className="mt-2"
                                        src={`${baseURL}${otherUser.image}`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src="/images/noImage.svg";
                                        }}
                                   />}
                <H4 color="black" className='mt-1'>{otherUser.name}</H4>
            </DialContainer>}
            {windowState === 'CONNECTING' && <DialContainer
                justify="center"
                align="center"
            >
                <H2 color="black" className='normal'>Connecting...</H2>
            </DialContainer>}
            {windowState === 'ENDED' && <DialContainer
                justify="center"
                align="center"
                style={{ height: '100vh' }}
            >
                <H2 color="second" className='normal'>Call Ended</H2>
                {otherUser.image && <Image
                                        className="mt-2"
                                        src={`${baseURL}${otherUser.image}`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src="/images/noImage.svg";
                                        }}
                                   />}
                <H4 color="black" className='mt-1'>{otherUser.name}</H4>
                <Button
                    size='md'
                    color='second'
                    className='mt-4'
                    elevated
                    onClick={() => window.close()}
                >
                    <FiX className='mr-1' />
                    Close
                </Button>
            </DialContainer>}
            {(windowState === 'REJECTED' || windowState === 'NOTANSWERED') && <DialContainer
                justify="center"
                align="center"
            >
                <H2 color="second" className='normal'>{windowState === 'REJECTED' ? 'Rejected' : 'Not Answered'}</H2>
                {otherUser.image && <Image
                                        className="mt-2"
                                        src={`${baseURL}${otherUser.image}`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src="/images/noImage.svg";
                                        }}
                                   />}
                <H4 color="black" className='mt-1'>{otherUser.name}</H4>
                <Button
                    size='md'
                    color='second'
                    className='mt-4'
                    elevated
                    onClick={() => window.close()}
                >
                    <FiX className='mr-1' />
                    Close
                </Button>
            </DialContainer>}
            <VBox justify='center' style={{ height: 'calc(100vh - 60px)' }}>
                <OtherVideo
                    style={{ display: windowState === 'INCALL' ? 'block' : 'none',
                            width: isMobile ? '100%' : 'auto',
                            height: isMobile ? 'auto' : '100%' }}
                    ref={remoteVideo}
                    autoPlay
                    playsInline
                />
            </VBox>
            <MyVideo
                ref={localVideo}
                autoPlay
                playsInline
                muted
                className="mr-2 mt-2"
                style={{ display: windowState === 'INCALL' && videoOn ? 'block' : 'none', width: isMobile ? 100 : 150 }}
            />
            {(windowState === 'REJECTED' || windowState === 'NOTANSWERED') ?
                <BottomPanel align="center" justify="center" className='px-2'>
                    <H3 color="white">Call again?</H3>
                    <IconBox
                        backgroundColor={colors.green}
                        align="center"
                        justify="center"
                        className="ml-2"
                        onClick={call}
                    >
                        <CallIcon />
                    </IconBox>
                </BottomPanel>
                :
                windowState === 'ENDED' ?
                    <></>
                    :
                    <BottomPanel align="center" justify="space-between" className='px-2'>
                        <HBox>
                             {prescriptionId && <Tooltip title='Manage prescription' arrow>
                                <Button color="third" size="sm" style={{ height: 30, padding: 16 }} onClick={() => setOpenPres(true)}>
                                    {/* <FiFileText size={16} className='mr-0_5' /> */}
                                    <HBox><P2 color='white'>R</P2><P3 color='white' style={{ position: 'relative', top: 6 }}>x</P3></HBox>
                                </Button>
                            </Tooltip>}
                        </HBox>
                        {windowState === 'INCALL' && <HBox>
                            <Tooltip title={micOn ? 'Mute' : 'Unmute'} arrow>
                                <IconBox borderColor={micOn ? colors.blue : colors.red} align="center" justify="center" className='mx-0_5' onClick={handleClickMic}>
                                    {micOn ? <MicOnIcon /> : <MicOffIcon />}
                                </IconBox>
                            </Tooltip>
                            <Tooltip title={videoOn ? 'Turn Off Camera' : 'Turn On Camera'} arrow>
                                <IconBox borderColor={videoOn ? colors.blue : colors.red} align="center" justify="center" className='mx-0_5' onClick={handleClickVideo}>
                                    {videoOn ? <VideoOnIcon /> : <VideoOffIcon />}
                                </IconBox>
                            </Tooltip>
                        </HBox>}
                        <Tooltip title='End Call' arrow>
                            <IconBox borderColor={colors.red} backgroundColor={colors.red} align="center" justify="center" onClick={handleEndCall}>
                                <CallIcon />
                            </IconBox>
                        </Tooltip>
                    </BottomPanel>
            }

            <CreatePrescriptionDlg
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={prescriptionId}
            />
        </VBox>
    )
}

export default connect(null, { setShowUppernav })(VideoCall);