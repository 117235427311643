import { useState, useEffect } from "react";
import styled from "styled-components";
import { FiCalendar, FiClock, FiChevronDown } from "react-icons/fi";
import { Menu, MenuItem, Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import { getDate, getTime } from "../../../utils";
import HealthProfileDlg from "../../../layouts/HealthProfileDlg";
import WriteMessageDlg from "../../../layouts/WriteMessageDlg";
import HealthRecordsDlg from "../../HealthRecordsDlg";
import PrescriptionPDF from "../../../layouts/PrescriptionPDF";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    background-color: ${colors.veryLightGrey};
`

const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const TimeIcon = styled(FiClock)`
    color: ${colors.red};
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

const AppointmentCard = ({ isMobile, id, patientUserId, patientName, ptId, prescriptionId,
                           date, startTime, endTime, patientIsOnline }) => {
    const [openHealthProfile, setOpenHealthProfile] = useState(false);
    const [openWriteMsg, setOpenWriteMsg] = useState(false);
    const [openHealthRec, setOpenHealthRec] = useState(false);
    const [openPres, setOpenPres] = useState(false);

    const [anchorElExpand, setAnchorElExpand] = useState(null);
    const openExpand = Boolean(anchorElExpand);
    const handleClickExpand = (event) => {
        setAnchorElExpand(event.currentTarget);
    };

    return (
        <Container className="mb-2">
            <VBox className="p-2">
                <HBox align='center'>
                    <H3>{patientName}</H3>
                    {patientIsOnline ? <Tooltip title='Online' arrow><OnlineIcon className="ml-0_5" /></Tooltip> : null}
                </HBox>
                <P2>Pt. ID: {ptId}</P2>
                <HBox className="mt-1"> 
                    <P2>Consulted on</P2>
                    <HBox align="center" className="ml-1">
                        <DateIcon />
                        <P2 className="ml-0_5">{getDate(date, isMobile ? "medium" : "long")}</P2>
                    </HBox>
                    <HBox align="center" className="ml-1">
                        <TimeIcon />
                        <P2 className="ml-0_5">
                            {getTime(startTime)}
                            {!isMobile && <> - {getTime(endTime)}</>}
                        </P2>
                    </HBox>
                </HBox>
            </VBox>
            <Line />
            <HBox className="p-1">
                <Button
                    size={isMobile ? "xs" : "sm"}
                    className="m-0_5"
                    outlined
                    onClick={() => setOpenPres(true)}
                >
                    Prescription
                </Button>
                <Button
                    size={isMobile ? "xs" : "sm"}
                    className="m-0_5"
                    outlined
                    onClick={() => setOpenHealthRec(true)}
                >
                    Health Records
                </Button>
                {!isMobile ?
                    <>
                        <Button
                            size="sm"
                            className="m-0_5"
                            outlined
                            onClick={() => setOpenHealthProfile(true)}
                        >
                            Health Profile
                        </Button>
                        {/* <Button size="sm" className="ml-1" outlined>Patient Details</Button> */}
                        <Button
                            size="sm"
                            className="m-0_5"
                            outlined
                            onClick={() => setOpenWriteMsg(true)}
                        >
                            Message
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            size={isMobile ? 'xs' : 'sm'}
                            className="m-0_5"
                            outlined
                            onClick={handleClickExpand}
                        >
                            <FiChevronDown className="mr-0_5" />
                            more
                        </Button>

                        <Menu
                            anchorEl={anchorElExpand}
                            open={openExpand}
                            onClose={() => setAnchorElExpand(null)}
                            onClick={() => setAnchorElExpand(null)}
                        >
                            <MenuItem onClick={() => setOpenHealthProfile(true)}>Health Profile</MenuItem>
                            {/* <MenuItem>Patient Details</MenuItem> */}
                            <MenuItem onClick={() => setOpenWriteMsg(true)}>Message</MenuItem>
                        </Menu>
                    </>
                }
            </HBox>

            <HealthProfileDlg
                patientUserId={patientUserId}
                ptName={patientName}
                open={openHealthProfile}
                setOpen={setOpenHealthProfile}
            />
            <WriteMessageDlg
                userId={patientUserId}
                open={openWriteMsg}
                setOpen={setOpenWriteMsg}
            />
            <HealthRecordsDlg
                ptName={patientName}
                patientUserId={patientUserId}
                open={openHealthRec}
                setOpen={setOpenHealthRec}
            />
            <PrescriptionPDF
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={prescriptionId}
            />
        </Container>
    )
}

export default AppointmentCard;