import styled from "styled-components";
import { FiFileText, FiCalendar, FiBarChart2 } from "react-icons/fi";
import { Link } from "react-router-dom";

import { VBox, HBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";

const Container = styled.div`
    background-color: ${colors.veryLightPurple};
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.purple};
    border-radius: 10px;
    /* box-shadow: 0px 1px 0px 0px ${colors.shadow}; */
    contain: content;

    :hover {
        box-shadow: 1px 1px 8px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 85%;
        height: auto;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 40%;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35%;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 25%;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 23%;
    }
`

const ColorBar = styled.div`
    height: 4px;
    width: 40%;
    border-radius: 0px 0px 4px 4px;
    background-color: ${colors.purple};
`

const FileTxtIcon = styled(FiFileText)`
    color: ${colors.darkGrey};
`

const CalIcon = styled(FiCalendar)`
    color: ${colors.darkGrey};
`

const SLine = styled.div`
    width: 85%;
    border-bottom: 0.5px solid ${colors.purple};
`

const SHBox = styled(HBox)`
    width: 100%;
`

const TxtBtn = styled(H6)`
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
    position: fixed;
    bottom: 24px;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        position: static;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const StatisticsCard = () => {
    return (
        <Container className="m-2">
            <ColorBar />
            <FiBarChart2 fontSize={25} className="mt-1 mb-1" />
            <H4 className="mb-1">My Account</H4>
            <SLine className="mb-2" />
            <HBox justify="space-between" style={{ width: '100%' }} className="px-4 mb-2">
                <VBox align="center" style={{ width: '50%' }}>
                    <H2 color="first">34</H2>
                    <P2>Attended patients</P2>
                </VBox>
                <VBox align="center" style={{ width: '50%' }}>
                    <H2 color="first">1</H2>
                    <P2>Appointments</P2>
                </VBox>
            </HBox>
            <HBox justify="flex-start" style={{ width: '100%' }} className="px-4 mb-2">
                <VBox align="center" style={{ width: '50%' }}>
                    <H2 color="first">6</H2>
                    <P2>Atteded patient</P2> 
                    <P2>current month</P2>
                </VBox>
            </HBox>
            <SLink to='/statistics'>
                <Button size="sm" color="fourth" outlined>View All</Button>
            </SLink>
        </Container>
    )
}

export default StatisticsCard;