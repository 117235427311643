import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FiCalendar, FiClock } from "react-icons/fi";

import { VBox, HBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getDate, getTime } from "../../../utils";

const Container = styled.div`
    background-color: ${colors.veryLightRed};
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.red};
    border-radius: 10px;
    /* box-shadow: 0px 1px 0px 0px ${colors.shadow}; */
    contain: content;

    :hover {
        box-shadow: 1px 1px 8px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 85%;
        height: auto;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 40%;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35%;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 25%;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 23%;
    }
`

const ColorBar = styled.div`
    height: 4px;
    width: 40%;
    border-radius: 0px 0px 4px 4px;
    background-color: ${colors.red};
`

const CalIcon = styled(FiCalendar)``

const ClockIcon = styled(FiClock)``

const Count = styled(P2)`
    padding: 2px;
    margin-left: 4px;
    color: ${colors.red};
    border: 1px solid ${colors.red};
    border-radius: 4px;
`

const SLine = styled.div`
    width: 85%;
    border-bottom: 0.5px solid ${colors.red};
`

const SHBox = styled(HBox)`
    width: 100%;
`

const SVBox = styled(VBox)`
    width: 100%;
`

const SLink = styled(Link)`
    text-decoration: none;
    position: fixed;
    bottom: 24px;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        position: static;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const AppointmentsCard = ({ isMobile, appointments }) => {
    return (
        <Container className="m-2">
            <ColorBar />
            <CalIcon fontSize={25} color={colors.darkGrey} className="mt-1 mb-1" />
            <HBox align="center" className="mb-1">
                <H4>Appointments</H4>
                <Count>{appointments.count}</Count>
            </HBox>
            <SLine className="mb-2" />
            {appointments.data ?
                <>
                {appointments.data.map((appointment, index) => (
                    <>
                    <SVBox className="pl-4 pr-4">
                        <H5>{appointment.patient_name}</H5>
                        <P2>{appointment.type} patient</P2>
                    </SVBox>
                    <SHBox className="px-4 mt-0_5 mb-2" align="center">
                        <CalIcon color={colors.blue} className="mr-0_5" />
                        <P2 className="mr-2">{getDate(appointment.consult_slot?.date, "medium")}</P2>
                        <ClockIcon color={colors.red} className="mr-0_5" />
                        <P2>
                            {getTime(appointment.consult_slot?.start_time)}
                            {!isMobile && <> - {getTime(appointment.consult_slot?.start_time)}</>}
                        </P2>
                    </SHBox>
                    </>
                ))}
                </>
                :
                <P2>No appointment upcoming.</P2>
            }
            <SLink to="/upcoming-appointments">
                <Button size="sm" color="second" outlined>View All</Button>
            </SLink>
        </Container>
    )
}

export default AppointmentsCard;