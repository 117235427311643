import { useState, useEffect } from "react";
import styled from "styled-components";
import { FiVideo, FiFileText, FiCalendar, FiClock, FiChevronDown } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { BsWhatsapp } from "react-icons/bs";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getDate, getTime } from "../../../utils";
import { OnlineChip, OfflineChip } from "../../../components/Chips";
import ReasonDlg from "./ReasonDlg";
import CreatePrescriptionDlg from "../../../layouts/CreatePrescriptionDlg";
import HealthProfileDlg from "../../../layouts/HealthProfileDlg";
import Confirm from "../../../layouts/Confirm";
import WriteMessageDlg from "../../../layouts/WriteMessageDlg";
import HealthRecordsDlg from "../../HealthRecordsDlg";
import PrescriptionPDF from "../../../layouts/PrescriptionPDF";
import { localstorage } from "../../../config/strings";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    background-color: ${colors.veryLightGrey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const TimeIcon = styled(FiClock)`
    color: ${colors.red};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const TxtBtn = styled(P2)`
    text-decoration: underline;
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const AppointmentCard = ({ id, prescriptionId, patientUserId, ptId, ptName, ptGender, ptAge, patientPhone,
                           date, startTime, endTime, type, reason, newOnlineStatusChange,
                           patientIsOnline, reloadData }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [ptIsOnline, setPtIsOnline] = useState(patientIsOnline);

    const [openReason, setOpenReason] = useState(false);
    const [openCreatePres, setOpenCreatePres] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [whatsappConfirm, setWhatsappConfirm] = useState(false);
    const [openHealthProfile, setOpenHealthProfile] = useState(false);
    const [openWriteMsg, setOpenWriteMsg] = useState(false);
    const [openHealthRec, setOpenHealthRec] = useState(false);

    const [anchorElExpand, setAnchorElExpand] = useState(null);
    const openExpand = Boolean(anchorElExpand);
    const handleClickExpand = (event) => {
        setAnchorElExpand(event.currentTarget);
    };

    const [openPres, setOpenPres] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (newOnlineStatusChange) {
            if (patientUserId == newOnlineStatusChange.user_id) {
                setPtIsOnline(newOnlineStatusChange.is_online);
            }
        }
    }, [newOnlineStatusChange]);
    useEffect(() => {setPtIsOnline(patientIsOnline)}, [patientIsOnline]);

    // Create zoom meeting
    const handleZoomMeeting = () => {
        enqueueSnackbar("Generating zoom meeting. Please wait a moment.", { persist: true });
        axios({
            method: 'POST',
            url: `doctor/zoom-meeting/`,
            data: {
                appointment_id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                enqueueSnackbar('Zoom meeting successfully created.', { variant: 'success' });

                var doctorName = response.data.doctor_name;
                var patientName = response.data.patient_name;
                var meetingNumber = response.data.meeting_number;
                var meetingPassword = response.data.meeting_password;
                notifyPatient(doctorName, patientName, meetingNumber, meetingPassword);
                messagePatient(meetingNumber, meetingPassword);
            } else {
                closeSnackbar();
                enqueueSnackbar('Zoom meeting create failed. Please try again.', { variant: 'error' });
                console.log('ZOOM MEETING CREATION FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Zoom meeting create error. Please try again.', { variant: 'error' });
            console.log('ZOOM MEETING CREATION ERROR', error);
        })
    }

    // Notify patient
    const notifyPatient = ( doctorName, patientName, meetingNumber, meetingPassword ) => {
        axios({
            method: 'POST',
            url: `chat/notification-list/`,
            data: {
                receiver_id: patientUserId,
                type: 'zoom_invitation',
                text: `${doctorName} joined the zoom meeting. Please join for consultation.`,
                data: JSON.stringify({patientName: patientName, meetingNumber: meetingNumber, password: meetingPassword})
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                if (isMobile) navigate('/messages');
                else window.open(`${process.env.REACT_APP_ZOOM_MEETING_URL}d/${meetingNumber}/${meetingPassword}/${doctorName}`);
            } else {
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                console.log('ZOOM INVITATION SEND FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            console.log('ZOOM INVITATION SEND ERROR', error);
        })
    }

    const messagePatient = ( meetingNumber, meetingPassword ) => {
        let message = `A Zoom meeting is created for the consultation. Please join in.\nMeeting ID: ${meetingNumber}\nPasscode: ${meetingPassword}`
        axios({
            method: 'POST',
            url: 'chat/inbox-list/',
            data: {
                receiver_id: patientUserId,
                message: message,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        }).then(res => {
            if (res.status === 201) {
                
            }
            else if (res.status === 200) {

            }
            else {
                console.log('MESSAGE POST FAILED');
            }
        }).catch(error => {
            console.log('MESSAGE POST ERROR', error);
        });
    }

    const handleFinish = () => {
        enqueueSnackbar('Finishing the consultation... Please wait a moment.', { persist: true });
        axios({
            method: 'PUT',
            url: `doctor/appointment-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                localStorage.setItem(localstorage.consultFinishPtUserId, patientUserId);  // To end call if there is any active call
                reloadData();
                enqueueSnackbar('Appointment finished.', { variant: 'success' });
            } else {
                console.log('APPOINTMENT FINISH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('APPOINTMENT FINISH ERROR', error);
        })
    }


    const handleWhatsAppCall = () => {
        window.open(`https://wa.me/${patientPhone}`, '_blank');
    };


    return (
        <Container className="mb-2">
            <HBox className="p-2" justify="space-between">
                <VBox>
                    <HBox align="center" className="mb-1">
                        <HBox align="center">
                            <DateIcon className="mr-0_5" />
                            <P2>{getDate(date, isMobile ? "medium" : "long")}</P2>
                        </HBox>
                        <HBox className="ml-1" align="center">
                            <TimeIcon className="mr-0_5" />
                            <P2>
                                {getTime(startTime)} - {getTime(endTime)}
                            </P2>
                        </HBox>
                    </HBox>
                    <HBox align='center'>
                        <H4 className="bold">{ptName}</H4>
                        {ptIsOnline ?
                            <OnlineChip className="ml-0_5"><H6 color='white'>Online</H6></OnlineChip>
                            :
                            <OfflineChip className="ml-0_5"><H6 color='white'>Offline</H6></OfflineChip>
                        }
                    </HBox>
                    <P2 color="second" className="mb-1">{type} patient</P2>
                    {reason && reason.length > 64 ?
                        <HBox>
                            <P2>{reason.slice(0, 64)}...</P2>
                            <TxtBtn color="second" className="ml-0_5" onClick={() => setOpenReason(true)}>more</TxtBtn>
                        </HBox>
                        :
                        reason
                    }
                </VBox>
                {/* <VBox justify="space-around">
                    <SLink to={`/call/${patientUserId}`} target="_blank" className="mb-1">
                        <Button color="second" size="sm" style={{ width: '100%' }}>
                            <FiVideo className={isMobile ? "" : "mr-0_5"} />
                            {!isMobile && 'Call'}
                        </Button>
                    </SLink>
                    <Button color="first" size="sm" onClick={() => setOpenCreatePres(true)}>
                        <FiFileText className={isMobile ? "" : "mr-0_5"} />
                        {!isMobile && 'Rx'}
                    </Button>
                </VBox> */}
                <HBox align='flex-start'>
                    <VBox align='center' className="mr-1">
                        <SLink to={`/call/${patientUserId}/${prescriptionId}`} target="_blank">
                            <Tooltip title={`Call ${ptName}`}>
                                <Button color="second" size="sm" style={{ height: 60, padding: 16 }}>
                                    <FiVideo size={20} className='mr-0_5' />
                                    Call
                                </Button>
                            </Tooltip>
                        </SLink>
                        {/* <Button 
                            onClick={() => setWhatsappConfirm(true)} 
                            color="second" 
                            size="sm" 
                            style={{ height: 60, padding: 16 }}
                            className="mt-1"
                        >
                            <BsWhatsapp size={20} className='mr-0_5' color="green"/>
                            Whatsapp
                        </Button> */}
                        <Tooltip title='Please use this when default video call fails.' arrow>
                            <H6
                                align='center'
                                className="mt-0_5 clickable"
                                style={{ textDecoration: 'underline' }}
                                onClick={() => setWhatsappConfirm(true)}
                            >
                                Or WhatsApp Call
                            </H6>
                        </Tooltip>
                        {/* Turning off zoom as jwt toked based auth is deprecated */}
                        {/* <Tooltip title='Please use this zoom meeting when default video call fails.' arrow>
                            <H6
                                align='center'
                                className="mt-0_5 clickable"
                                style={{ textDecoration: 'underline' }}
                                onClick={handleZoomMeeting}
                            >
                                Or Zoom Meeting
                            </H6>
                        </Tooltip> */}
                    </VBox>
                    <VBox align='center'>
                        <Tooltip title='Manage prescription' arrow>
                            <Button color="third" size="sm" style={{ height: 60, padding: 16 }} onClick={() => setOpenCreatePres(true)}>
                                <FiFileText size={20} className='mr-0_5' />
                                <HBox><P2 color='white'>R</P2><P3 color='white' style={{ position: 'relative', top: 6 }}>x</P3></HBox>
                            </Button>
                        </Tooltip>
                        <Tooltip title='Prescription preview' arrow>
                            <H6 align='center' className="mt-0_5 clickable" style={{ textDecoration: 'underline' }} onClick={() => setOpenPres(true)}>Preview</H6>
                        </Tooltip>
                    </VBox>
                </HBox>
            </HBox>
            <Line />
            <HBox justify="flex-end" className="p-1">
                {!isMobile ? 
                    <>
                        <Button
                            size="sm"
                            className="m-0_5"
                            outlined
                            onClick={() => setOpenWriteMsg(true)}
                        >
                            Message
                        </Button>
                        {/* <Button size="sm" className="mr-1" outlined>Patient Details</Button> */}
                        <Button
                            size="sm"
                            className="m-0_5"
                            outlined
                            onClick={() => setOpenHealthRec(true)}
                        >
                            Health Records
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            size={isMobile ? 'xs' : 'sm'}
                            className="m-0_5"
                            outlined
                            onClick={handleClickExpand}
                        >
                            <FiChevronDown className="mr-0_5" />
                            more
                        </Button>

                        <Menu
                            anchorEl={anchorElExpand}
                            open={openExpand}
                            onClose={() => setAnchorElExpand(null)}
                            onClick={() => setAnchorElExpand(null)}
                        >
                            <MenuItem onClick={() => setOpenHealthRec(true)}>Health Records</MenuItem>
                            {/* <MenuItem>Patient Details</MenuItem> */}
                            <MenuItem onClick={() => setOpenWriteMsg(true)}>Message</MenuItem>
                        </Menu>
                    </>
                }
                <Button
                    size={isMobile ? 'xs' : 'sm'}
                    className="m-0_5"
                    outlined
                    onClick={() => setOpenHealthProfile(true)}
                >
                    Health Profile
                </Button>
                <Button
                    color="second"
                    size={isMobile ? 'xs' : 'sm'}
                    className="m-0_5"
                    outlined
                    onClick={() => setOpenConfirm(true)}
                >
                    <b>Finish Consultation</b>
                </Button>
            </HBox>

            <ReasonDlg open={openReason} setOpen={setOpenReason} text={reason} />
            <CreatePrescriptionDlg
                open={openCreatePres}
                setOpen={setOpenCreatePres}
                prescriptionId={prescriptionId}
            />
            <HealthProfileDlg
                patientUserId={patientUserId}
                ptName={ptName}
                open={openHealthProfile}
                setOpen={setOpenHealthProfile}
            />
            <WriteMessageDlg
                userId={patientUserId}
                open={openWriteMsg}
                setOpen={setOpenWriteMsg}
            />
            <HealthRecordsDlg
                ptName={ptName}
                patientUserId={patientUserId}
                open={openHealthRec}
                setOpen={setOpenHealthRec}
            />
            <Confirm
                message="Are you sure to finish this appointment?"
                btnColor="second"
                onConfirm={handleFinish}
                open={openConfirm}
                setOpen={setOpenConfirm}
            />
            <PrescriptionPDF
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={prescriptionId}
            />
            <Confirm
                message="Your phone number will be visible to the patient. Would you like to proceed with the WhatsApp call?"
                btnColor="first"
                onConfirm={handleWhatsAppCall}
                open={whatsappConfirm}
                setOpen={setWhatsappConfirm}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    newOnlineStatusChange: state.chat.newOnlineStatusChange,
});

export default connect(mapStateToProps, {  })(AppointmentCard);