import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiSearch, FiX } from "react-icons/fi";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { IconButton, Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    overflow-y: hidden;
`

const SearchIcon = styled(FiSearch)`
    color: ${colors.darkBlue};
`

const Input = styled.input`
    width: 80%;
    height: 2.5em;
    border: 0px;
    color: ${colors.darkGrey};
    outline: none;
    font-size: 1rem;
    padding: 8px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const Image = styled.img`
    height: 25px;
    width: 25px;
    border-radius: 13px;
`

const UserContainer = styled.div`
    width: '100%';
    /* border: 1px solid ${colors.grey}; */
    background-color: ${props => props.selected ? colors.lightGrey : 'white'};
    border-radius: 5px;
    cursor: pointer;

    :hover {
        /* background-color: ${colors.lightGrey}; */
        box-shadow: 0px 1px 5px 0px ${colors.shadow};
        transition: box-shadow 100ms;
    }
`

const MessageInput = styled.input`
    border-radius: 1.25em;
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    // ::placeholder {
    //     color: ${colors.grey};
    // }
    :focus {
        outline: 1px solid ${colors.grey};
    }
`

const UserCard = ({ image, name, type, helpStr, selected, onClick }) => {
    return (
        <UserContainer className="p-1 mb-1" selected={selected} onClick={onClick}>
            <HBox align="center" justify="space-between">
                <HBox align="center">
                    <Image
                        src={`${baseURL}${image}`}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src="/images/noImage.svg";
                        }}
                    />
                    <P1 className={selected ? 'bold ml-1' : 'ml-1'}>{name}</P1>
                </HBox>
                <P2 className={selected ? 'bold' : ''} color={type==='Doctor' ? 'second' : 'first'}>{helpStr}</P2>
            </HBox>
        </UserContainer>
    );
}

const NewMessageDlg = ({ open, setOpen, initInboxes, inboxIndex, setInboxIndex }) => {
    const pageSize = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [users, setUsers] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [searchKey, setSearchKey] = useState("");
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [message, setMessage] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        const init = () => {
            setSelectedId(null);
            setSearchKey("");
            setOffset(0);
            setLimit(pageSize);
            setHasMore(true);
            setUsers([]);
            setMessage("");
        }
        if (open) init();
    }, [open]);

    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    useEffect(() => () => {
        if (!isMobile) {
            if (inboxIndex === null) setInboxIndex(0);
        }
    }, [inboxIndex]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `chat/user-list/?key=${searchKey}&offset=${offset}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setUsers(users.concat(response.data.users));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('USER LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('USER LIST FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleSearch = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setOffset(0);
                    setLimit(pageSize);
                    setHasMore(true);
                    setUsers([]);
            }, 300)
        )
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClick = (id, name) => {
        // setOpen(false);
        setSelectedId(id);
    }

    const handleMessage = () => {
        if (validate()) {
            axios({
                method: 'POST',
                url: 'chat/inbox-list/',
                data: {
                    receiver_id: selectedId,
                    message: message,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            }).then(res => {
                if (res.status === 201) {
                    initInboxes();
                    setInboxIndex(null);
                    setOpen(false);
                    console.log('INBOX CREATED');
                }
                else if (res.status === 200) {
                    initInboxes();
                    setInboxIndex(null);
                    setOpen(false);
                    console.log('NEW MESSAGE TO ALREADY CREATED INBOX');
                }
                else {
                    console.log('NEW MESSAGE FAILED');
                    enqueueSnackbar('Operation failed!', {variant: 'error'});
                }
            }).catch(error => {
                console.log('NEW MESSAGE ERROR', error);
                enqueueSnackbar('Operation error!', {variant: 'error'});
            });
        }
    }

    const validate = () => {
        if (!selectedId) {
            enqueueSnackbar('Please select a contact.', {variant: 'default'});
            return false;
        }
        if (message.trim().length === 0) {
            enqueueSnackbar('Please write a message.', {variant: 'default'});
            return false;
        }

        return true;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <Container>
                <HBox align="center" justify='space-between' className="mt-1 mb-1">
                    <HBox align="center" style={{ width: '80%' }}>
                        <SearchIcon fontSize={16} className="ml-2" />
                        <Input
                            placeholder="Search contacts..."
                            type="text"
                            autoFocus
                            value={searchKey}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </HBox>
                    <IconButton
                        className="mr-2"
                        onClick={() => setOpen(false)}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
                <Line />
                {searchKey&&count!==null ?
                    <HBox className="px-2 py-1" style={{ backgroundColor: colors.lightGrey }}>
                        <P2>Found</P2>
                        <P2 color='third' className="bold" style={{ marginLeft: 4, marginRight: 4 }}>{count}</P2>
                        <P2>{count === 1 ? 'contact' : 'contacts'}.</P2>
                    </HBox>
                    :
                    <HBox className="px-2 py-1" style={{ backgroundColor: colors.lightGrey }}>
                        <P2>Search and select contact. Write your message. Then press <span style={{ color: colors.blue }}>"Message"</span> button.</P2>
                    </HBox>
                }
                <Line />
                <Scrollbars
                    style={{ height: '30vh' }}
                    onUpdate={handleScrollUpdate}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    {users.length ?
                        <VBox className="m-2">
                            {users.map((user, index) => (
                                <UserCard
                                    index={index}
                                    name={user.name}
                                    image={user.image}
                                    type={user.type}
                                    helpStr={user.help_str}
                                    selected={user.id === selectedId}
                                    onClick={() => handleClick(user.id, user.name)}
                                />
                            ))}
                        </VBox>
                        :
                        <VBox justify="center" align="center" style={{ height: '80%' }}>
                            <Image src="/images/search.png" />
                        </VBox>
                    }
                </Scrollbars>
                <Line />
                <HBox className="p-1" justify="center" style={{ width: '100%', backgroundColor: colors.lightGrey }}>
                    <MessageInput
                        className="mx-1 p-1"
                        placeholder="Write your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </HBox>
                <Line />
                <HBox justify="flex-end" className="p-1">
                    <Button
                        size='sm'
                        color='first'
                        className="mx-1"
                        onClick={handleMessage}
                    >
                        Message
                    </Button>
                </HBox>
            </Container>
        </Dialog>
    )
}

export default NewMessageDlg;