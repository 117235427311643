import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import './App.css';
import { UpperNav } from './layouts/Navbar';
import Login from './pages/Login';
import PasscodeVerification from './pages/PasscodeVerification';
import Register from './pages/Register';
import ForgetPassword from './pages/ForgetPassword';
import Dashboard from './pages/Dashboard';
import UpcomingAppointments from './pages/UpcomingAppointments';
import PreviousAppointments from './pages/PreviousAppointments';
import Messages from './pages/Messages';
import MyProfile from './pages/MyProfile';
import MyFees from './pages/MyFees';
import Password from './pages/Password';
import VideoCall from './pages/VideoCall';
import { checkAuthentication } from './services/actions/authAction';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

function App({ isAuthenticated, isVerified, showUppernav, checkAuthentication }) {
    useEffect(() => {
        checkAuthentication();
    }, []);

    let initPath;
    if (!isAuthenticated) {
        initPath = '/login';
    }
    else if (!isVerified && isAuthenticated) {
        initPath = '/passcode-verification';
    }
    else {
        initPath = '/dashboard';
    }

    return (
        <BrowserRouter>
            <UpperNav style={showUppernav ? {} : {display: 'none'}} />
            <Routes>
                <Route path="/" element={<Navigate to={initPath} />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forget-password' element={<ForgetPassword />} />
                <Route path='/passcode-verification' element={isAuthenticated ? <PasscodeVerification /> : <Login />} />
                <Route path='/dashboard' element={isAuthenticated ? <Dashboard /> : <Login />} />
                <Route path='/upcoming-appointments' element={isAuthenticated ? <UpcomingAppointments /> : <Login />} />
                <Route path='/previous-appointments' element={isAuthenticated ? <PreviousAppointments /> : <Login />} />
                <Route path='/messages' element={isAuthenticated ? <Messages /> : <Login />} />
                <Route path='/profile' element={isAuthenticated ? <MyProfile /> : <Login />} />
                <Route path='/fees' element={isAuthenticated ? <MyFees /> : <Login />} />
                <Route path='/password' element={isAuthenticated ? <Password /> : <Login />} />
                <Route path='/call/:receiverId' element={isAuthenticated ? <VideoCall /> : <Login />} />
                <Route path='/call/:receiverId/:prescriptionId' element={isAuthenticated ? <VideoCall /> : <Login />} />
            </Routes>
        </BrowserRouter>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified,
    showUppernav: state.auth.showUppernav,
});

export default connect(mapStateToProps, { checkAuthentication })(App);
