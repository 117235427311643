import { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { FiHelpCircle } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { InputText } from "../../../components/InputText";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import Alert from "../../../components/Alert";
import Tooltip from "../../../components/Tooltip";
import logoMain from "../../../assets/images/logo_main.png";

const FormContainer = styled.div`
    //width: 25vw;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #ffffff 0%, hsl(0, 0%, 97%) 100%);
    overflow: visible;
    border-radius: 10px;
    border: 0px solid #222;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 90vw;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 50vw;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35vw;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 30vw;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 25vw;
    }
`

const LoginBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SLink = styled(Link)`
    color: ${colors.darkBlue};
`

const Select = styled.select`
    border-radius: 5px;
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    ::placeholder {
        color: ${colors.grey};
    }
    :focus {
        outline: 1px solid ${colors.blue};
    }
`

const HelpIcon = styled(FiHelpCircle)`
    font-size: 0.75rem;
    display: block;
`

const HelpIconComponent = forwardRef(function (props, ref) {  // As tooltip is not working on react icons
  // Spread the props to the underlying DOM element.
  return <div {...props} ref={ref}>
            <HelpIcon />
         </div>
});

const Form = () => {
    let phonePrefix = '+88';
    const { state } = useLocation();
    let navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [bmdc, setBmdc] = useState("");
    const [doctorType, setDoctorType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [bmdcError, setBmdcError] = useState("");
    const [doctorTypeError, setDoctorTypeError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password1Error, setPassword1Error] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    const [alertMsg, setAlertMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    useEffect(() => {
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setPassword1("");
        setPassword2("");

        setAlertMsg("");
        setSuccessMsg("");

        closeSnackbar();
    }, []);

    const validate = () => {
        var ok = true;
        if (bmdc.trim() === "") {
            setBmdcError("This field cannot be kept blank.");
            ok = false;
        }
        else if (bmdc.includes('A-')) {
            setBmdcError("Please exclude 'A-'.");
            ok = false;
        }
        else if (bmdc.match(/^[0-9\b]+$/) === null) {
            setBmdcError("Please put only numeric digits. Ex: 1234.");
            ok = false;
        }
        else {
            setBmdcError("");
            ok = true;
        }

        if (doctorType === "") {
            setDoctorTypeError("Please select an option.");
            ok = false;
        }
        else {
            setDoctorTypeError("");
            ok = true;
        }

        if (firstName.trim() === "") {
            setFirstNameError("This field cannot be kept blank.");
            ok = false;
        }
        else {
            setFirstNameError("");
        }

        if (lastName.trim() === "") {
            setLastNameError("This field cannot be kept blank.");
            ok = false;
        }
        else {
            setLastNameError("");
        }

        if (phone === "") {
            setPhoneError("This field cannot be kept blank.");
            ok = false;
        }
        else if (phone.match(/^01\d{9}$/) === null) {
            setPhoneError("Please enter a valid phone number.");
            ok = false;
        }
        else {
            setPhoneError("");
        }
        
        if (email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || email === "") {
            setEmailError("");
        }
        else {
            setEmailError("Please put a valid email address or clear if not necessary.");
            ok = false;
        }

        if (password1.length < 8) {
            setPassword1Error("Minimum password length is eight.");
            ok = false;
        }
        else {
            setPassword1Error("");
        }

        if (password1 !== password2) {
            setPassword2Error("Passwords do not match.")
            ok = false;
        }
        else {
            setPassword2Error("");
        }

        return ok;
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const submit = () => {
        if (validate()) {
            enqueueSnackbar("Registering...", { persist: true });
            axios({
                method: 'POST',
                url: `auth/register/`,
                data: {
                    bmdc: bmdc,
                    doctor_type: doctorType,
                    first_name: firstName.trim(),
                    last_name: lastName.trim(),
                    phone: phonePrefix+phone,
                    email: email,
                    type: 'Doctor',
                    password1: password1,
                    password2: password2
                }
            }).then(res => {
                setAlertMsg('');
                closeSnackbar();
                if (res.status === 201) {
                    setSuccessMsg('Registration success.');

                    // Clear fields
                    setFirstName("");
                    setLastName("");
                    setPhone("");
                    setEmail("");
                    setPassword1("");
                    setPassword2("");

                    enqueueSnackbar("Redirecting to login page...", { persist: true });

                    // Redirect to login page
                    delay(1500).then(() => navigate(state.prevLocation));
                }
            }).catch(error => {
                setSuccessMsg('');
                closeSnackbar();
                if (error.message === 'Network Error') {
                    setAlertMsg('Network error.');
                }
                else if (error.response.status === 400) {
                    setAlertMsg(error.response.data.message);
                }
                else {
                    setAlertMsg(error.message);
                }
                console.log('REGISTER ERROR', error);
            });
        }
    }

    return (
        <div>
            <FormContainer className='p-3'>
                <img
                    style={{ height: 80, alignSelf: 'center' }}
                    className=""
                    src={logoMain}
                    alt="logo"
                />
                <H4 align='center' className='mb-3'>Register to Doctor Portal</H4>
                {successMsg && <Alert severity="success" className="mb-2">{successMsg}</Alert>}
                {alertMsg && <Alert className='mb-2'>{alertMsg}</Alert>}
                <HBox align='flex-start'>
                    <HBox style={{ width: '50%' }}>
                        <HBox align="center">
                            <P2 className="mr-0_5">BMDC No.</P2>
                            <Tooltip placement="top" title="Please input only numeric digit, without 'A-'. Example: 1234.">
                                <HelpIconComponent />
                            </Tooltip>
                        </HBox>
                        <InputText
                            style={{ width: '100%' }}
                            className='mr-1'
                            type='text'
                            helpText={bmdcError}
                            value={bmdc}
                            onChange={e => setBmdc(e.target.value)}
                        />
                    </HBox>
                    <HBox style={{ width: '50%' }}>
                        <P2>Doctor's Type</P2>
                        <Select onChange={(e) => setDoctorType(e.target.value)}>
                            <option value='' style={{display: 'none'}}></option>
                            <option value='MBBS'>MBBS</option>
                            <option value='BDS'>BDS</option>
                        </Select>
                        {doctorTypeError && <P2 color='second'>{doctorTypeError}</P2>}
                    </HBox>
                </HBox>
                <HBox align='flex-start' className='mt-1'>
                    <HBox style={{ width: '50%' }}>
                        <InputText
                            style={{ width: '100%' }}
                            className='mr-1'
                            label='First Name'
                            type='text'
                            helpText={firstNameError}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </HBox>
                    <HBox style={{ width: '50%' }}>
                        <InputText
                            style={{ width: '100%' }}
                            label='Last Name'
                            type='text'
                            helpText={lastNameError}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </HBox>
                </HBox>
                <HBox className="mt-1" align="flex-start">
                    <HBox style={{ width: '50%' }}>
                        <HBox align="center">
                            <P2 className="mr-0_5">Phone</P2>
                            <Tooltip placement="top" title="Must be a Bangladeshi phone number.">
                                <HelpIconComponent />
                            </Tooltip>
                        </HBox>
                        <InputText
                            className="mr-1"
                            style={{ width: '100%' }}
                            type='tel'
                            placeholder="01XXXXXXXXX"
                            helpText={phoneError}
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </HBox>
                    <VBox style={{ width: '50%' }}>
                        <HBox align="center">
                            <P2 className="mr-0_5">Email</P2>
                            <Tooltip placement="top" title="This field is optional. If you fill up this field, this can be used in login.">
                                <HelpIconComponent />
                            </Tooltip>
                        </HBox>
                        <InputText
                            style={{ width: '100%' }}
                            type='email'
                            placeholder="Optional, for alternative login"
                            helpText={emailError}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </VBox>
                </HBox>
                <HBox className="mt-1" align="flex-start">
                    <HBox style={{ width: '50%' }}>
                        <HBox align="center">
                            <P2 className="mr-0_5">Password</P2>
                            <Tooltip placement="top" title="Minimum password length is eight.">
                                <HelpIconComponent />
                            </Tooltip>
                        </HBox>
                        <InputText
                            className="mr-1"
                            style={{ width: '100%' }}
                            type='password'
                            helpText={password1Error}
                            value={password1}
                            onChange={e => setPassword1(e.target.value)}
                        />
                    </HBox>
                    <HBox style={{ width: '50%' }}>
                        <InputText
                            style={{ width: '100%' }}
                            label='Re-enter Password'
                            type='password'
                            helpText={password2Error}
                            value={password2}
                            onChange={e => setPassword2(e.target.value)}
                        />
                    </HBox>
                </HBox>
                <LoginBtnContainer className='mt-2'>
                    <Button size='sm' color='first' onClick={submit}>Register</Button>
                </LoginBtnContainer>
            </FormContainer>
            <FormContainer className='mt-2 p-2 mb-4'>
                <P2 align='center'>Already have an account? <SLink to='/login'>Login</SLink></P2>
            </FormContainer>
        </div>
    )
}

export default Form;
