import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import colors from '../config/colors';

export default styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.lightGrey,
        color: colors.darkGrey,
        maxWidth: 220,
        fontSize: '0.75rem',
        border: `1px solid ${colors.grey}`,
        boxShadow: `1px 1px 5px 0px ${colors.shadow}`,
    },
}));
