import styled from "styled-components";
import { Link } from "react-router-dom";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";

const FormContainer = styled.div`
    //width: 25vw;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #ffffff 0%, hsl(0, 0%, 97%) 100%);
    overflow: visible;
    border-radius: 10px;
    border: 0px solid #222;

    @media only screen and (max-width: 599px) { // xs
        width: 80vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 899px) {  // sm
        width: 50vw;
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {  // md
        width: 35vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1535px) {  // lg
        width: 25vw;
    }
    @media only screen and (min-width: 1536px) {  // xl
        width: 25vw;
    }
`

const SLink = styled(Link)`
    color: ${colors.darkBlue};
`

const Container = styled(VBox)`
    height: 100%;
    width: 100%;
    // box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    // border: 0px solid #222;
    // border-radius: 10px;
`

export const LAd = () => {
    return (
        <Container>
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 50px 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_4.png'
                alt="ad"
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_7.png'
                alt="ad"
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_5.png'
                alt="ad."
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 50px 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_6.png'
                alt="ad."
            />
        </Container>
    )
}

export const RAd = () => {
    return (
        <Container>
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '50px 0 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_1.jpg'
                alt="ad"
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_3.png'
                alt="ad"
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 0 0', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad_1.jpg'
                alt="ad."
            />
            <img
                style={{ height: '25%', width: '100%', border: '1px solid grey', borderRadius: '0 0 0 50px', boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.15)' }}
                src='images/advertisements/ad.jpg'
                alt="ad."
            />
        </Container>
    )
}