import axios from 'axios';

import { getUUID } from '../../utils';
import { localstorage } from '../../config/strings';

import {
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_REFRESH,
    LOGIN,
    VERIFICATION,
    LOGOUT,
    NETWORK_ERROR,
    LOGIN_ERROR,
    VERIFICATION_ERROR,
    SHOW_UPPERNAV,
} from './types';

// Check user authentication
export const checkAuthentication = () => async dispatch => {
    if (localStorage.getItem(localstorage.access)) {
        await axios({
            method: 'POST',
            url: 'auth/token/verify/',
            data: {
                token: localStorage.getItem(localstorage.access),
            }
        }).then(res => {
            if (res.status === 200) {
                checkVerification(dispatch);
                dispatch({
                    type: AUTHENTICATION_SUCCESS,
                    payload: res.data
                });
            }
            else if (res.data.code === 'token_not_valid') {
                refreshToken(dispatch);
            }
        }).catch(error => {
            if (error.message === 'Network Error') {
                dispatch({
                    type: NETWORK_ERROR,
                });
            }
            console.log('TOKEN VERIFY ERROR', error);
        });
    }
};

// User login
export const login = (phoneOrEmail, password, loginWith) => dispatch => {
    axios({
        method: 'POST',
        url: 'auth/token/',
        data: {
            device_id: getUUID(),
            type: 'Doctor',
            phone_or_email: phoneOrEmail,
            password: password
        }
    }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: LOGIN,
                payload: Object.assign({}, res.data, {login_with: loginWith}),   // Concat
            });
        }
    }).catch(error => {
        if (error.message === 'Network Error') {
            dispatch({
                type: NETWORK_ERROR,
            });
        }
        else if (error.response.status === 403) {
            dispatch({
                type: LOGIN_ERROR,
            });
        }
        console.log('LOGIN ERROR', error);
    });
};

export const verifyPasscode = (passcode) => dispatch => {
    axios({
        method: 'POST',
        url: 'auth/verify-passcode/',
        data: {
            device_id: getUUID(),
            login_with: localStorage.getItem(localstorage.loginwith),
            passcode: passcode,
        },
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
        }
    }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: VERIFICATION,
                payload: res.data
            });
        }
    }).catch(error => {
        if (error.message === 'Network Error') {
            dispatch({
                type: NETWORK_ERROR,
            });
        }
        else if (error.response.status === 400) {
            dispatch({
                type: VERIFICATION_ERROR,
            });
        }
        console.log('PASSCODE VERIFY ERROR', error);
    });
}

// User logout
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}

const refreshToken = async (dispatch) => {
    await axios({
        method: 'POST',
        url: 'auth/token/refresh/',
        data: {
            refresh: localStorage.getItem(localstorage.refresh)
        }
    }).then(res => {
        if (res.status === 200) {
            localStorage.setItem(localstorage.access, res.data.access);
            
            checkVerification(dispatch);
            dispatch({
                type: AUTHENTICATION_REFRESH,
                payload: res.data
            });
        }
    }).catch(error => {
        if (error.message === 'Network Error') {
            dispatch({
                type: NETWORK_ERROR,
            });
        }
        console.log('TOKEN REFRESH ERROR', error);
    });
}

const checkVerification = async (dispatch) => {
    await axios({
        method: 'POST',
        url: 'auth/check-verification/',
        data: {
            device_id: getUUID(),
            login_with: localStorage.getItem(localstorage.loginwith),
        },
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
        }
    }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: VERIFICATION,
                payload: res.data
            });
        }
    }).catch(error => {
        if (error.message === 'Network Error') {
            dispatch({
                type: NETWORK_ERROR,
            });
        }
        else if (error.response.status === 401) {
            dispatch({
                type: LOGIN_ERROR,
            });
        }
        console.log('LOGIN ERROR', error);
    });
}

export const setShowUppernav = (show) => dispatch => {
    dispatch({
        type: SHOW_UPPERNAV,
        payload: { showUppernav: show },
    });
}