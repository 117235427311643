import styled from "styled-components";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import Form from "./components/Form";
import { LAd, RAd } from "./components/Advertisement";

const ImageBG = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url('/images/loginBg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(248deg, #c2f2ff 0%, rgb(19, 176, 221) 100%);
    opacity: 0.91;
    display: flex;
    justify-content: center;
    align-items: center;
    position: 'absolute';
    top: 0;
`

const LoginContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
`

const AdContainer = styled.div`
    width: 10vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
`

const Login = ({ isAuthenticated, isVerified }) => {
    const { pathname } = useLocation();  // Previous location; to redirect after login

    if (!isVerified && isAuthenticated) {  // Currently passcode verification turned off; need to manage prev location redirection after turning on
        return (<Navigate to="/passcode-verification" />);
    }
    else if (isAuthenticated) {
        if (pathname === '/login') return (<Navigate to="/dashboard" />);
        else return (<Navigate to={pathname} />);
    }

    return (
        <ImageBG>
            <Overlay />
            {/* <AdContainer style={{ left: 0 }}>
                <LAd />
            </AdContainer> */}
            <LoginContainer>
                <Form />
            </LoginContainer>
            {/* <AdContainer style={{ right: 0 }}>
                <RAd />
            </AdContainer> */}
        </ImageBG>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps, { })(Login);
