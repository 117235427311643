import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { FiEdit3, FiCheck, FiX, FiTrash, FiPlusSquare } from "react-icons/fi";
import { FaCamera } from "react-icons/fa";
import { useSnackbar } from 'notistack';

import { Button, NavBtnActive, NavBtn, IconButton } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { genderData, specialtiesData } from "../../data";
import { getDate, getTime } from "../../utils";
import Footer from "../../layouts/Footer";
import { LowerNav } from "../../layouts/Navbar";
import { localstorage } from "../../config/strings";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const Image = styled.img`
    height: 120px;
    width: 120px;
    border-radius: 60px;
    border: 1px solid ${colors.grey};
`

const AddPhotoIcon = styled(FaCamera)`
    position: fixed;
    right: 5px;
    bottom: 5px;
    color: ${colors.red};
    font-size: 20px;
    cursor: pointer;
    z-index: 1;

    :hover {
        color: ${colors.darkRed};
    }
`

const AddIcon = styled(FiPlusSquare)`
    color: ${colors.blue};
    cursor: pointer;
`

const ImageOverlay = styled.div`
    position: fixed;
    top: 0px;
    height: 120px;
    width: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0;
    cursor: pointer;

    :hover {
        opacity: 0.05;
        transition: 300ms;
    }
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const Select = styled.select`
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};

    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const DeleteIcon = styled(FiTrash)`
    color: ${colors.red};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const HighlightChip = styled(HBox)`
    background-color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 2px;
`

const getDaysStr = (schedule) => {
    let days = [];
    if (schedule.saturday) days.push('Sat');
    if (schedule.sunday) days.push('Sun');
    if (schedule.monday) days.push('Mon');
    if (schedule.tuesday) days.push('Tue');
    if (schedule.wednesday) days.push('Wed');
    if (schedule.thursday) days.push('Thu');
    if (schedule.friday) days.push('Fri');

    return (
        <>
        {days.map((day, index) => (
            index === 0 ?
                <span>{day}</span>
                :
                <span>{',' + day}</span>
        ))}
        </>
    )
}

const AffiliationCard = ({ isMobile, index, id, institution, designation, department, startDate,
                           endDate, showInSummary, affiliations, setAffiliations, className }) => {
    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox className="mb-1" align="center">
                <H4 className='bold'>{institution}</H4>
                {showInSummary && <HighlightChip className='p-0_5 ml-1'>
                    <P3 color="white">Highlighted</P3>
                </HighlightChip>}
            </HBox>
            <HBox align="flex-end" justify="space-between" style={{ width: '100%' }}>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Designation</P3>
                    <P1>{designation}</P1>
                </VBox>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Department</P3>
                    <P1>{department ? department : 'Not set'}</P1>
                </VBox>
                {/* <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} /> */}
            </HBox>
        </VBox>
    )
}

const ChamberCard = ({ isMobile, index, id, address, chambers, setChambers, className }) => {
    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox className="" align="center" justify='space-between'>
                <H4>{address}</H4>
                {/* <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} /> */}
            </HBox>
        </VBox>
    )
}

const AvailabilityCard = ({ isMobile, id, index, schedule, schedules, setSchedules, className }) => {
    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox align="flex-end" justify="space-between" style={{ width: '100%' }}>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Days</P3>
                    <P1>{getDaysStr(schedule)}</P1>
                </VBox>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Time</P3>
                    <P1>{getTime(schedule.startTime)} - {getTime(schedule.endTime)}</P1>
                </VBox>
                {/* <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} /> */}
            </HBox>
        </VBox>
    )
}

const MyProfile = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profile, setProfile] = useState({
        image: null, title: "", first_name: "", last_name: "",
        bangla_title: "", bangla_name: "", gender: "",
        address: null, email: null, phone: null, doctor_type: "",
        bmdc: "", qualification: "", specialty: "", about: "",
        account_name: "", account_number: "", bank_name: "", branch_name: "",
        mobile_banking_number: "", mobile_banking_operator: ""
    });

    const [affiliations, setAffiliations] = useState([]);
    const [chambers, setChambers] = useState([]);
    const [appointSchedules, setAppointSchedules] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';

            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())

        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => setAffiliations(profile.affiliations), [profile]);
    useEffect(() => setChambers(profile.chambers), [profile]);
    useEffect(() => setAppointSchedules(profile.appointment_schedules), [profile]);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `doctor/doctor-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
            .then((response) => {
                setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    setProfile(response.data);
                } else {
                    console.log('PROFILE FETCH FAILED', response.status);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                closeSnackbar();
                console.log('PFOFILE FETCH ERROR', error);
            })
    }

    const fieldHtml = (name, field, type = 'text') => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
                <P1>
                    {profile[field] ?
                        type === 'date' ?
                            getDate(profile[field], 'long')
                            :
                            profile[field]
                        :
                        'Not Set'
                    }
                </P1>
            </td>
        </tr>
    )

    return (
        <>
        <LowerNav selected='my-account' />
        <HBox>
            {isMobile && <TopPanel justify='center' className='p-3'>
                <Button size='sm' color='first' className="m-0_5">Profile</Button>
                <SLink to='/fees' className="m-0_5">
                    <Button
                        size='sm'
                        color='first'
                        outlined
                    >
                        Fees
                    </Button>
                </SLink>
                <SLink to='/password' className="m-0_5">
                    <Button
                        size='sm'
                        color='first'
                        outlined
                    >
                        Change Password
                    </Button>
                </SLink>
            </TopPanel>}
            {!isMobile && <LeftPanel style={{ height: 'calc(100vh - 150px)' }}>
                <NavBtnActive size="sm" color="first" className="mt-8" outlined>
                    <div className="ml-1">Profile</div>
                </NavBtnActive>
                <SLink to='/fees' className="mt-1">
                    <NavBtn size="sm" color="first" outlined>
                        <div className="ml-1">Fees</div>
                    </NavBtn>
                </SLink>
                <SLink to='/password' className="mt-1">
                    <NavBtn size="sm" color="first" outlined>
                        <div className="ml-1">Change Password</div>
                    </NavBtn>
                </SLink>
            </LeftPanel>}
            <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                <Scrollbars
                    style={{ height: 'calc(100vh - 150px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4' }} />
                    }>
                    <VBox className="p-3" style={{ width: '100%' }}>
                        <Paper className={isMobile ? 'p-2' : 'p-4'}>
                            <HBox align="center">
                                <VBox className="mr-3" style={{ contain: 'content' }}>
                                    <Image
                                        src={`${baseURL}${profile.image}`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "/images/noImage.svg";
                                        }}
                                    />
                                    {/* <AddPhotoIcon onClick={() => setUploadPicOpen(true)} />
                                    <ImageOverlay onClick={() => setUploadPicOpen(true)} /> */}
                                </VBox>
                                <VBox className={isMobile ? 'mt-2' : ''}>
                                    <H3 color="first" className="mb-1">Photo</H3>
                                    {/* <P2 style={{ wordWrap: 'break-word' }}>A photo helps to communicate in a better way.</P2> */}
                                </VBox>
                            </HBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <VBox>
                                <H3 color="first">Personal Information</H3>
                                {/* <P2>We keep your personal information secure.</P2> */}

                                <table style={{ width: '100%' }} className="mt-2">
                                    {fieldHtml('TITLE', 'title')}
                                    {fieldHtml('FIRST NAME', 'first_name')}
                                    {fieldHtml('LAST NAME', 'last_name')}
                                    {fieldHtml('TITLE (বাংলা)', 'bangla_title')}
                                    {fieldHtml('NAME (বাংলা)', 'bangla_name')}
                                    {fieldHtml('GENDER', 'gender', genderData)}
                                    {fieldHtml('ADDRESS', 'address')}
                                </table>
                            </VBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <VBox>
                                <H3 color="first">Contact Information</H3>

                                <table style={{ width: '100%' }} className="mt-2">
                                    {fieldHtml('PHONE', 'phone')}
                                    {fieldHtml('EMAIL', 'email')}
                                </table>
                            </VBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <VBox>
                                <H3 color="first">Bank Information</H3>

                                <table style={{ width: '100%' }} className="mt-2">
                                    {fieldHtml('ACC. NAME', 'account_name' )}
                                    {fieldHtml('ACC. NUMBER', 'account_number')}
                                    {fieldHtml('BANK NAME', 'bank_name')}
                                    {fieldHtml('BRANCH', 'branch_name')}
                                </table>
                            </VBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <VBox>
                                <H3 color="first">Mobile Banking Information</H3>

                                <table style={{ width: '100%' }} className="mt-2">
                                    {fieldHtml('MOBILE NO.', 'mobile_banking_number' )}
                                    {fieldHtml('OPERATOR NAME', 'mobile_banking_operator')}
                                </table>
                            </VBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <VBox>
                                <H3 color="first">Professional Information</H3>

                                <table style={{ width: '100%' }} className="mt-2">
                                    {fieldHtml('DOCTOR TYPE', 'doctor_type')}
                                    {fieldHtml('BMDC NO.', 'bmdc' )}
                                    {fieldHtml('QUALIFICATION', 'qualification')}
                                    {fieldHtml('EXPERIENCE', 'experience')}
                                    {fieldHtml('SPECIALTY', 'specialty')}
                                </table>
                            </VBox>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <HBox justify="space-between" align="center">
                                <H3 color='first' className="mb-2">About You</H3>
                            </HBox>
                            <P1>{profile.about}</P1>
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                <H3 color="first">Affiliations</H3>
                            </HBox>
                            {affiliations?.map((affiliation, index) => (
                                <AffiliationCard
                                    className={index===0 ? 'mt-3' : 'mt-4'}
                                    isMobile={isMobile}
                                    index={index}
                                    id={affiliation.id}
                                    institution={affiliation.institution}
                                    designation={affiliation.designation}
                                    department={affiliation.department}
                                    showInSummary={affiliation.show_in_summary}
                                    affiliations={affiliations}
                                    setAffiliations={setAffiliations}
                                />
                            ))}
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                <H3 color="first">Chambers</H3>
                                {/* <AddIcon onClick={() => setOpenAddCham(true)} /> */}
                            </HBox>
                            {chambers?.map((chamber, index) => (
                                <ChamberCard
                                    className={index===0 ? 'mt-3' : 'mt-4'}
                                    isMobile={isMobile}
                                    index={index}
                                    id={chamber.id}
                                    address={chamber.address}
                                    chambers={chambers}
                                    setChambers={setChambers}
                                />
                            ))}
                        </Paper>

                        <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                            <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                <H3 color="first">Availability</H3>
                                {/* <AddIcon onClick={() => setOpenAppointSchedule(true)} /> */}
                            </HBox>
                            {appointSchedules?.map((appointSchedule, index) => (
                                <AvailabilityCard
                                    className={index===0 ? 'mt-3' : 'mt-4'}
                                    isMobile={isMobile}
                                    key={appointSchedule.id}
                                    id={appointSchedule.id}
                                    index={index}
                                    schedule={appointSchedule.schedule}
                                    schedules={appointSchedules}
                                    setSchedules={setAppointSchedules}
                                />
                            ))}
                        </Paper>
                    </VBox>
                </Scrollbars>
            </CenterPanel>
            {!isMobile && <RightPanel style={{ height: 'calc(100vh - 150px)' }}></RightPanel>}
        </HBox>
        {!isMobile && <Footer />}
        </>
    )
}

export default MyProfile;
