import styled from "styled-components";

import Form from "./components/Form";

const ImageBG = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url('/images/loginBg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(248deg, #c2f2ff 0%, rgb(19, 176, 221) 100%);
    opacity: 0.91;
    display: flex;
    justify-content: center;
    align-items: center;
    position: 'absolute';
    top: 0;
`

const LoginContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
`

const Register = () => {
    return (
        <ImageBG>
            <Overlay />
            <LoginContainer>
                <Form />
            </LoginContainer>
        </ImageBG>
    )
}

export default Register;
