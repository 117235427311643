import { useState } from "react";
import styled from "styled-components";
import { FiCalendar, FiTrash2, FiFileText, FiDownload } from "react-icons/fi";
import moment from "moment";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Menu, MenuItem, Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import { localstorage } from "../../../config/strings";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
`

const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const DeleteIcon = styled(FiTrash2)`
    color: ${colors.red};
    cursor: pointer;
`

const ShowIcon = styled(FiFileText)`
    color: ${colors.blue};
    cursor: pointer;
`

const DownloadIcon = styled(FiDownload)`
    color: ${colors.green};
    cursor: pointer;
`

const HealthRecordCard = ({ id, index, title, reportDate, hrFiles, reloadRecords }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const [anchorElView, setAnchorElView] = useState(null);
    const openView = Boolean(anchorElView);
    const handleClickView = (event) => {
        setAnchorElView(event.currentTarget);
    };
    const handleFileView = (file) => {
        const link = document.createElement('a');
        link.href = baseURL+file;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setAnchorElView(null);
    };

    const [anchorElDownload, setAnchorElDownload] = useState(null);
    const openDownload = Boolean(anchorElDownload);
    const handleClickDownload = (event) => {
        setAnchorElDownload(event.currentTarget);
    };
    const handleFileDownload = (file) => {
        const link = document.createElement('a');
        link.href = baseURL+file;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', true);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setAnchorElDownload(null);
    };

    // Currently not in use
    const handleDownloadHealthRecord = () => {
        enqueueSnackbar('Zipping files...', {persist: true});
        axios({
            method: "GET",
            url: 'patient/health-record-download/',
            params: {
                id: id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`,
            },
            responseType: 'arraybuffer',
        }).then(function (res) {
            closeSnackbar();
            if (res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data], {type:'application/zip'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', title+'.zip');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                enqueueSnackbar('Zip file ready for download.', {variant: 'success'});
            }
            else {
                enqueueSnackbar('Record download failed!', {variant: 'error'});
            }
        }).catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Record download error!', {variant: 'error'});
        });
    }

    return (
        <Container className="my-2 mx-3">
            <HBox align="center" justify="space-between" className="p-2">
                <VBox className="ml-1">
                    <H5>{title}</H5>
                    <HBox align="center" className="mt-0_5">
                        <DateIcon />
                        <P2 className="ml-0_5">{moment(reportDate).format('D MMMM YYYY')}</P2>
                    </HBox>
                </VBox>
                <HBox>
                    <Tooltip title='View'>
                        <ShowIcon
                            className="ml-1"
                            onClick={handleClickView}
                        />
                    </Tooltip>
                    <Tooltip title='Download'>
                        <DownloadIcon
                            className="ml-1"
                            onClick={handleClickDownload}
                        />
                    </Tooltip>
                </HBox>

                <Menu
                    anchorEl={anchorElView}
                    open={openView}
                    onClose={() => setAnchorElView(null)}
                >
                    {hrFiles.map((hrFile, index) => (
                        <MenuItem onClick={() => handleFileView(hrFile.file)}>File {hrFile.order}</MenuItem>
                    ))}
                </Menu>
                <Menu
                    anchorEl={anchorElDownload}
                    open={openDownload}
                    onClose={() => setAnchorElDownload(null)}
                >
                    {hrFiles.map((hrFile, index) => (
                        <MenuItem onClick={() => handleFileDownload(hrFile.file)}>File {hrFile.order}</MenuItem>
                    ))}
                </Menu>
            </HBox>
        </Container>
    )
}

export default HealthRecordCard;