import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Scrollbars } from "react-custom-scrollbars";
import { FiHelpCircle } from "react-icons/fi";

import { HBox, VBox } from "../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { Button, IconButton } from "../components/Buttons";
import CheckChoice from "../components/CheckChoice";
import CheckButton from "../components/CheckButton";
import { InputText, InputTextarea } from "../components/InputText";
import Tooltip from '../components/Tooltip';
import colors from "../config/colors";
import { localstorage } from '../config/strings';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const MsgBox = styled(HBox)`
    background-color: ${colors.lightGrey};
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    max-width: fit-content;
    align-self: center;
`

const HelpIcon = styled(FiHelpCircle)`
    font-size: 0.75rem;
    display: block;
`

const HelpIconComponent = forwardRef(function (props, ref) {  // As tooltip is not working on react icons
    // Spread the props to the underlying DOM element.
    return <div {...props} ref={ref}>
              <HelpIcon />
           </div>
});

const HealthProfileDlg = ({ patientUserId, ptName, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // ******************  Health profile start  ********************
    const [drugSensitivity, setDrugSensitivity] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [takingMedication, setTakingMedication] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [healthCondition, setHealthCondition] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [anySurgery, setAnySurgery] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [covidPositive, setCovidPositive] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);

    const [params, setParams] = useState({
        height_ft: "", height_in: "", weight: "", bmi: "",  blood_pressure_systolic: "",
        blood_pressure_diastolic: "", temperature: "", pulse: "",
        blood_glucose: "", pulse_oximetry: ""
    });
    // ******************  Health profile end  *********************

    // *************** Behavioral health history start *******************
    const [reason, setReason] = useState([
        {text: 'Emotional problem', checked: false},
        {text: 'Relationship issue', checked: false},
        {text: 'Substance abuse', checked: false},
        {text: 'Other', checked: false},
    ]);
    const otherReasonChLim = 256;  // Sync with database limit
    const [otherReason, setOtherReason] = useState("");
    const [previousDiagnosis, setPreviousDiagnosis] = useState([
        {text: 'Alcohol dependance', checked: false},
        {text: 'Anxiety', checked: false},
        {text: 'Bipolar disorder', checked: false},
        {text: 'Depression', checked: false},
        {text: 'Obsessive compulsive disorder (OCD)', checked: false},
        {text: 'Panic attacks', checked: false},
        {text: 'Schizophrenia', checked: false},
        
    ]);
    const [hospitalizedBefore, setHospitalizedBefore] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false},
    ]);
    const [familyPrevDiagnosis, setFamilyPrevDiagnosis] = useState([
        {text: 'Alcohol dependance', checked: false},
        {text: 'Bipolar disorder', checked: false},
        {text: 'Depression', checked: false},
        {text: 'Obsessive compulsive disorder (OCD)', checked: false},
        {text: 'Schizophrenia', checked: false},
        {text: 'Unknown', checked: false},
    ]);
    const [familyHospitalizedBefore, setFamilyHospitalizedBefore] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false},
    ]);
    // **************** Behavioral Health History end  *******************

    // **********************  Life style start  *************************
    const [adequateSleep, setAdeauateSleep] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [exercise, setExercise] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [fluShots, setFluShots] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [smoke, setSmoke] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [alcohol, setAlcohol] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [recrDrugs, setRecrDrugs] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [toxicChem, setToxicChem] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [disability, setDisability] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    // ***********************  Life style end  **************************

    // ************************  Family history start ********************
    const [diseasesHistory, setDiseasesHistory] = useState([
        {text: 'Asthma', checked: false},
        {text: 'Cancer', checked: false},
        {text: 'Cystic fibrosis', checked: false},
        {text: 'Diabetes', checked: false},
        {text: 'Early/unexplained death', checked: false},
        {text: 'Elevated cholesterol', checked: false},
        {text: 'Heart diseases', checked: false},
        {text: 'High blood pressure', checked: false},
        {text: 'Metabolic diseases', checked: false},
        {text: 'Seasonal allergies', checked: false},
        {text: 'Sickle cell asthma', checked: false},
        {text: 'Stroke', checked: false},
        {text: 'Thyroid disease', checked: false},
        {text: 'Other', checked: false},
    ]);
    const otherHistoryChLim = 256;  // Sync with database limit
    const [otherHistory, setOtherHistory] = useState("");
    // *************************  Family history end  ********************

    useEffect(() => {
        if (open) fetchData();
    }, [open]);

    const fetchData = async () => {
        enqueueSnackbar("Loading...", { persist: true });
        await axios({
            method: 'GET',
            url: `doctor/health-profile-details/`,
            params: {
                user_id: patientUserId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setHealthHistoryData(response.data.health_history);
                setBehavioralHealthHistoryData(response.data.behavioral_health_history);
                setLifeStyleData(response.data.life_style);
                setFamilyHistoryData(response.data.family_history);
            } else {
                console.log('HEALTH PROFILE GET FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('HEALTH PROFILE GET ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const submit = () => {
        if (validate()) {
            axios({
                method: 'PUT',
                url: `doctor/health-profile-details/`,
                data: {
                    user_id: patientUserId,
                    health_history: {
                        drug_sensitivity: getBoolean(drugSensitivity),
                        taking_medication: getBoolean(takingMedication),
                        health_condition: getBoolean(healthCondition),
                        any_surgery: getBoolean(anySurgery),
                        covid_positive: getBoolean(covidPositive),
                        height: getHeight(),
                        weight: params.weight,
                        bmi: params.bmi,
                        blood_pressure_systolic: params.blood_pressure_systolic,
                        blood_pressure_diastolic: params.blood_pressure_diastolic,
                        temperature: params.temperature,
                        pulse: params.pulse,
                        blood_glucose: params.blood_glucose,
                        pulse_oximetry: params.pulse_oximetry
                    },
                    behavioral_health_history: {
                        reason: getChoices(reason),
                        reason_other: otherReason,
                        previous_diagnosis: getChoices(previousDiagnosis),
                        hospitalized_before: getBoolean(hospitalizedBefore),
                        previous_family_diagnosis: getChoices(familyPrevDiagnosis),
                        family_hospitalized_before: getBoolean(familyHospitalizedBefore)
                    },
                    life_style: {
                        adequate_sleep: getBoolean(adequateSleep),
                        exercise: getBoolean(exercise),
                        yearly_flu_shots: getBoolean(fluShots),
                        smoke: getBoolean(smoke),
                        alcohol: getBoolean(alcohol),
                        recreational_drugs: getBoolean(recrDrugs),
                        toxic_chemicals: getBoolean(toxicChem),
                        disability: getBoolean(disability)
                    },
                    family_history: {
                        diseases_history: getChoices(diseasesHistory),
                        diseases_history_other: otherHistory
                    }
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
                }
            })
            .then((response) => {
                closeSnackbar();
                if (response.status === 202) {
                    enqueueSnackbar("Successfully updated.", { variant: 'success' });
                } else {
                    enqueueSnackbar("Operation failed!", { variant: 'error' });
                    console.log('HEALTH PROFILE UPDATE FAILED', response.status);
                }
            })
            .catch((error) => {
                enqueueSnackbar("Operation error!", { variant: 'error' });
                console.log('HEALTH PROFILE UPDATE ERROR', error);
            })
        }
    }

    const validate = () => {
        return true
    }

    const setHealthHistoryData = (data) => {
        setBoolean(data.drug_sensitivity, drugSensitivity, setDrugSensitivity);
        setBoolean(data.taking_medication, takingMedication, setTakingMedication);
        setBoolean(data.health_condition, healthCondition, setHealthCondition);
        setBoolean(data.any_surgery, anySurgery, setAnySurgery);
        setBoolean(data.covid_positive, covidPositive, setCovidPositive);
        
        let heightArr = convertHeight(data.height);
        setParams({...params, height_ft: heightArr[0], height_in: heightArr[1],
                   weight: data.weight, bmi: data.bmi,
                   blood_pressure_systolic: data.blood_pressure_systolic,
                   blood_pressure_diastolic: data.blood_pressure_diastolic,
                   temperature: data.temperature, pulse: data.pulse,
                   blood_glucose: data.blood_glucose, pulse_oximetry: data.pulse_oximetry});
    }

    const setBehavioralHealthHistoryData = (data) => {
        setBoolean(data.hospitalized_before, hospitalizedBefore, setHospitalizedBefore);
        setBoolean(data.family_hospitalized_before, familyHospitalizedBefore, setFamilyHospitalizedBefore);

        setChoices(data.reason, reason, setReason);
        setOtherReason(data.reason_other ? data.reason_other : "");
        setChoices(data.previous_diagnosis, previousDiagnosis, setPreviousDiagnosis);
        setChoices(data.previous_family_diagnosis, familyPrevDiagnosis, setFamilyPrevDiagnosis);
    }

    const setLifeStyleData = (data) => {
        setBoolean(data.adequate_sleep, adequateSleep, setAdeauateSleep);
        setBoolean(data.exercise, exercise, setExercise);
        setBoolean(data.yearly_flu_shots, fluShots, setFluShots);
        setBoolean(data.smoke, smoke, setSmoke);
        setBoolean(data.alcohol, alcohol, setAlcohol);
        setBoolean(data.recreational_drugs, recrDrugs, setRecrDrugs);
        setBoolean(data.toxic_chemicals, toxicChem, setToxicChem);
        setBoolean(data.disability, disability, setDisability);
    }

    const setFamilyHistoryData = (data) => {
        setChoices(data.diseases_history, diseasesHistory, setDiseasesHistory);
        setOtherHistory(data.diseases_history_other ? data.diseases_history_other : "");
    }

    const setBoolean = (value, state, setState) => {
        if (value === true) {
            let values = [...state];
            values[0].checked = true;
            setState(values);
        }
        else if (value === false) {
            let values = [...state];
            values[1].checked = true;
            setState(values);
        }
        else {
            // do nothing
        }
    }

    const getBoolean = (state) => {
        if (state[0].checked) return true;
        else if (state[1].checked) return false;
        else return null;
    }

    const setChoices = (choices, state, setState) => {
        if (choices) {
            if (typeof(choices) === 'string') {
                setIndvChoice(choices, state, setState);
            }
            else {
                for (let i=0; i<choices.length; i++) {
                    let choice = choices[i];
                    setIndvChoice(choice, state, setState);
                }
            }
        }
    }

    const setIndvChoice = (choice, state, setState) => {
        for (let j=0; j<state.length; j++) {
            let values = [...state];
            if (values[j].text === choice) {
                values[j].checked = true;
                setState(values);
                break;
            }
        }
    }

    const getChoices = (state) => {
        let choices = [];
        for (let i=0; i<state.length; i++) {
            if (state[i].checked) {
                choices.push(state[i].text);
            }
        }

        return String(choices);
    }

    const convertHeight = (height) => {
        if (height) {
            let feet = Math.floor(height / 12);
            let inch = height%12;

            return [feet, inch];
        }
        return ["", ""];
    }

    const getHeight = () => {
        if (params.height_ft && params.height_in) {
            return parseFloat(params.height_ft)*12 + parseFloat(params.height_in);
        }
        else if (params.height_ft) {
            return parseFloat(params.height_ft)*12;
        }
        else if (params.height_in) {
            return parseFloat(params.height_in);
        }
        else {
            return "";
        }
    }

    return (
        <Dialog maxWidth="lg" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <HBox align='center'>
                    <H4 className='ml-2'>Health Profile</H4>
                    <P2 className='mx-0_5'>of</P2>
                    <P2 className='' color='second'>{ptName}</P2>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Scrollbars
                style={{ height: 'calc(100vh - 175px)' }}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <VBox className='py-3 px-4'>
                    <HBox align='center'>
                        <H3 color='first' className='mr-0_5'>Health History</H3>
                        <Tooltip placement="top" title={<P2>
                                                            This form is intended to be filled up by the patient.
                                                            You can also edit this form. The patient will view the changes what you will made in this form.
                                                    </P2>}
                        >
                            <HelpIconComponent />
                        </Tooltip>
                    </HBox>
                    <VBox className='mt-2'>
                        <P1>Is the patient currently taking any medication?</P1>
                        <CheckButton className="mt-1" items={takingMedication} setItems={setTakingMedication} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient have any allergies or drug sensitivities?</P1>
                        <CheckButton className="mt-1" items={drugSensitivity} setItems={setDrugSensitivity} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient have any health conditions?</P1>
                        <CheckButton className="mt-1" items={healthCondition} setItems={setHealthCondition} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Has the patient been tested COVID-19 positive?</P1>
                        <CheckButton className="mt-1" items={covidPositive} setItems={setCovidPositive} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient ever have any surgeries or medical procedures?</P1>
                        <CheckButton className="mt-1" items={anySurgery} setItems={setAnySurgery} size="md" radio={true} />
                    </VBox>
                    
                    <VBox className="mt-3">
                        <H5>Body measurements:</H5>
                        <VBox className="mx-4">
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Current Height:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.height_ft}
                                    onChange={(e) => setParams({...params, height_ft: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">ft</P2>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.height_in}
                                    onChange={(e) => setParams({...params, height_in: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">in</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Current Weight:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.weight}
                                    onChange={(e) => setParams({...params, weight: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">kg</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>BMI (calculated):</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.bmi}
                                    style={{ width: 80 }}
                                    disabled
                                />
                                <P2 className="ml-0_5">kg/m^2</P2>
                            </HBox>
                        </VBox>
                    </VBox>

                    <VBox className="mt-3">
                        <H5>Current vitals:</H5>
                        <VBox className="mx-4">
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Blood Pressure:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    placeholder="Systolic"
                                    value={params.blood_pressure_systolic}
                                    onChange={(e) => setParams({...params, blood_pressure_systolic: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P1 className="ml-0_5">/</P1>
                                <InputText
                                    type="number"
                                    className="ml-0_5"
                                    placeholder="Diastolic"
                                    value={params.blood_pressure_diastolic}
                                    onChange={(e) => setParams({...params, blood_pressure_diastolic: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">mmHg</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Temperature:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.temperature}
                                    onChange={(e) => setParams({...params, temperature: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">&deg;F</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Pulse:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.pulse}
                                    onChange={(e) => setParams({...params, pulse: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">BPM</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Blood Glucose:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.blood_glucose}
                                    onChange={(e) => setParams({...params, blood_glucose: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">mg/dL</P2>
                            </HBox>
                            <HBox align="center" className="mt-1">
                                <P1 style={{ width: 200 }}>Pulse Oximetry:</P1>
                                <InputText
                                    type="number"
                                    className="ml-1"
                                    value={params.pulse_oximetry}
                                    onChange={(e) => setParams({...params, pulse_oximetry: e.target.value})}
                                    style={{ width: 80 }}
                                />
                                <P2 className="ml-0_5">%SpO2</P2>
                            </HBox>
                        </VBox>
                    </VBox>

                    <H3 color='first' className='mt-6'>Behavioral Health History</H3>
                    <VBox className='mt-2'>
                        <P1>What is the patient's reason for seeking behavioral health care? (check all that apply)</P1>
                        <CheckChoice
                            items={reason}
                            setItems={setReason}
                            className='p-2'
                        />
                        {reason.at(-1).checked &&
                            <VBox className="mx-3">
                                <InputTextarea
                                    placeholder="Please type other reasons..."
                                    value={otherReason}
                                    onChange={(e) => setOtherReason(e.target.value.slice(0, otherReasonChLim))}
                                />
                                <P2 align="right">{otherReason.length} / {otherReasonChLim}</P2>
                            </VBox>
                        }
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Has the patient ever been diagnosed with any of the following conditions?</P1>
                        <CheckChoice
                            items={previousDiagnosis}
                            setItems={setPreviousDiagnosis}
                            className='p-2'
                        />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Has the patient ever been hospitalized for mental health or substance abuse treatment?</P1>
                        <CheckButton className="mt-1" items={hospitalizedBefore} setItems={setHospitalizedBefore} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Has anyone in the patient's family been diagnosed with any of the follwing?</P1>
                        <CheckChoice
                            items={familyPrevDiagnosis}
                            setItems={setFamilyPrevDiagnosis}
                            className='p-2'
                        />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Has any family member ever been hospitalized for mental health or substance abuse treatment?</P1>
                        <CheckButton className="mt-1" items={familyHospitalizedBefore} setItems={setFamilyHospitalizedBefore} size="md" radio={true} />
                    </VBox>

                    <H3 color='first' className='mt-6'>Life Style</H3>
                    <VBox className='mt-2'>
                        <P1>Does the patient exercise?</P1>
                        <CheckButton className="mt-1" items={exercise} setItems={setExercise} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient get at least 7 hours of sleep daily?</P1>
                        <CheckButton className="mt-1" items={adequateSleep} setItems={setAdeauateSleep} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient smoke or use tobacco?</P1>
                        <CheckButton className="mt-1" items={smoke} setItems={setSmoke} size="md" radio={true} />
                    </VBox><VBox className='mt-3'>
                        <P1>Does the patient drink alcohol?</P1>
                        <CheckButton className="mt-1" items={alcohol} setItems={setAlcohol} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient use recreational drugs?</P1>
                        <CheckButton className="mt-1" items={recrDrugs} setItems={setRecrDrugs} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Is or has the patient ever been disabled?</P1>
                        <CheckButton className="mt-1" items={disability} setItems={setDisability} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient get yearly flu shots?</P1>
                        <CheckButton className="mt-1" items={fluShots} setItems={setFluShots} size="md" radio={true} />
                    </VBox>
                    <VBox className='mt-3'>
                        <P1>Does the patient work around hazardous or toxic chemicals?</P1>
                        <CheckButton className="mt-1" items={toxicChem} setItems={setToxicChem} size="md" radio={true} />
                    </VBox>
                    
                    <H3 color='first' className='mt-6'>Family History</H3>
                    <VBox className='mt-2'>
                        <P1>Does anyone in the patient's family have the following? (check all that apply)</P1>
                        <CheckChoice
                            items={diseasesHistory}
                            setItems={setDiseasesHistory}
                            className='p-2'
                        />
                        {diseasesHistory.at(-1).checked &&
                            <VBox className="mx-3">
                                <InputTextarea
                                    placeholder="Please type other history..."
                                    value={otherHistory}
                                    onChange={(e) => setOtherHistory(e.target.value.slice(0, otherHistoryChLim))}
                                />
                                <P2 align="right">{otherHistory.length} / {otherHistoryChLim}</P2>
                            </VBox>
                        }
                    </VBox>
                </VBox>

            </Scrollbars>
            <Line />
            <HBox align="center" justify="flex-end" className="py-1 px-2">
                <HBox>
                    <CloseBtn size="sm" className='mr-1' onClick={handleClose}>Close</CloseBtn>
                    <Button size="sm" color="first" onClick={submit}>Save</Button>
                </HBox>
            </HBox>
        </Dialog>
    )
}

export default HealthProfileDlg;