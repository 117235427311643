import styled from "styled-components";
import { FiCalendar } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { P2 } from "../../../components/Typography";
import colors from "../../../config/colors";

const Icon = styled(FiCalendar)`
    color: white;
`

const IconContainer = styled.div`
    width: 40px;
    background-color: ${colors.grey};
    border-radius: 5px 0px 0px 5px;
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Input = styled.input`
    height: 2.5em;
    border-radius: 0px 5px 5px 0px;
    border-left: none;
    border-bottom: 1px solid ${colors.grey};
    border-right: 1px solid ${colors.grey};
    border-top: 1px solid ${colors.grey};
    outline: none;
    background-color: white;
    color: ${colors.darkGrey};
`

const DateInput = ({ label, value, onChange }) => {
    return (
        <HBox>
            <IconContainer>
                <P2 style={{ color: colors.darkGrey }}>{label}</P2>
            </IconContainer>
            <Input
                className="p-1"
                type='date'
                value={value}
                onChange={onChange}
            />
        </HBox>
    )
}

export default DateInput;