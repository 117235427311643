import { useEffect, useState } from "react";
import styled from "styled-components";
import { MdOutlineDashboard } from "react-icons/md";
import { FiCalendar, FiMail, FiUser, FiBarChart2, FiSettings, FiLogOut, FiMenu } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Drawer, Divider } from '@mui/material';
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../components/Containers";
import { H3, H4, P1, P2, P3 } from "../components/Typography";
import { Button } from "../components/Buttons";
import colors from "../config/colors";
import responsive from "../config/responsive";
import { removeHttp } from "../utils";
import { logout } from "../services/actions/authAction";
import { setNewMessage, setNewOnlineStatusChange } from "../services/actions/chatAction";
import { localstorage } from "../config/strings";
import logoMain from "../assets/images/logo_main.png";

/* ************************ Upper nav *********************** */
const LogoutBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    /* text-decoration: none; */
`

const NavbarUp = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`

const A = styled.a`
    text-decoration: none;
`

let callSocket;

const _UpperNav = ({ isAuthenticated, setNewMessage, logout,
                     setNewOnlineStatusChange, style }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) getUserData();
    }, [isAuthenticated]);

    function getUserData() {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        }).then(res => {
            if (res.status === 200) {
                connectSocket(res.data.id);
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    function connectSocket(id) {
        let ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
        
        callSocket = new WebSocket(
            ws_scheme
            + removeHttp(process.env.REACT_APP_SERVER_URL)
            // + window.location.host
            + 'ws/chat/'
        );
        
        callSocket.onopen = event => {
            callSocket.send(JSON.stringify({
                type: 'login',
                data: {
                    id: id
                }
            }));
        }
    
        callSocket.onmessage = (e) => {
            let response = JSON.parse(e.data);
            let type = response.type;
            
            if(type === 'connection') {
                console.log(response.data.message);

            }

            else if (type === 'message_received') {
                onNewMessage(response.data);
            }

            else if (type === 'onlinestatus_changed') {
                onNewOnlineStatusChange(response.data);
            }
        }

        const onNewMessage = (data) => {
            setNewMessage(data);
        }

        const onNewOnlineStatusChange = (data) => {
            setNewOnlineStatusChange(data);
        }
    }

    const handleLogout = () => {
        callSocket.close();
        logout();
        navigate('/login');
    }

    return (
        <NavbarUp style={style}>
            <HBox align="center">
                <img
                    style={{ height: 45 }}
                    className="ml-1 clickable"
                    src={logoMain}
                    alt="logo"
                    onClick={() => {window.open(process.env.REACT_APP_MYHEALTH_WEBSITE_URL)}}
                />
                {/* <H3 style={{ color: '#F01111' }}>MY</H3>
                <H3 style={{ color: '#00B8FF' }}>HEALTH</H3> */}
                <P2 className="ml-1" color='default'>Doctor Portal</P2>
            </HBox>
            <LogoutBtn onClick={handleLogout}>
                <FiLogOut />
                <P1 className='mr-2 ml-1'>Logout</P1>
            </LogoutBtn>
        </NavbarUp>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export const UpperNav = connect(mapStateToProps, { setNewMessage, logout,
                                                   setNewOnlineStatusChange })(_UpperNav);
/* ****************** End upper nav ************************ */

/* ******************** Lower nav ************************** */
const LowerNavContainer = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    display: flex;
    justify-content: center;
    z-index: 1;
`

const NavbarLow = styled.div`
    width: 60%;
    height: 60px;
    display: flex;
    background-color: ${colors.blue};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: stretch;
    justify-content: space-around;
`

const SNavLink = styled(NavLink)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    :hover {
        border-bottom: 3px solid white;
    }
    /* :active {  // Not working
        border-bottom: 3px solid white;
    } */
`

const NavItemContainerMobile = styled.div`
    // width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
`

const NavbarLowMobile = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    background-color: ${colors.blue};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    // align-items: stretch;
    justify-content: space-between;
`

const SNavLinkMobile = styled(NavLink)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
`

export const _LowerNav = ({ selected, newMessage, setNewMessage }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isMobile, setIsMobile] = useState(false);
    const [openMenuDrawer, setOpenMenuDrawer] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (newMessage) {
            if (selected !== 'messages') {
                enqueueSnackbar(<HBox align='center'>
                                    <P2 color='white' className="mr-1">You have a new message.</P2>
                                    <Button size='sm' color='first' onClick={() => navigate('/messages')}>View</Button>
                                </HBox>, { style: {  }, persist: true });
            }
            setNewMessage(null);
        }
        
    }, [newMessage]);

    const getSelectedTitle = () => {
        if (selected === 'dashboard') {
            return (
                <HBox align='center'>
                    <MdOutlineDashboard fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Dashboard</H4>
                </HBox>
            );
        }
        else if (selected === 'appointments') {
            return (
                <HBox align='center'>
                    <FiCalendar fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Appointments</H4>
                </HBox>
            );
        }
        else if (selected === 'messages') {
            return (
                <HBox align='center'>
                    <FiMail fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Messages</H4>
                </HBox>
            );
        }
        else if (selected === 'my-account') {
            return (
                <HBox align='center'>
                    <FiUser fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>My Account</H4>
                </HBox>
            );
        }
        else {
            return null;
        }
    }

    return (
        <LowerNavContainer>
            {!isMobile ?
                <NavbarLow>
                    <SNavLink to='/dashboard' style={{ borderBottom: selected==='dashboard' ? '3px solid white' : "" }}>
                        <MdOutlineDashboard fontSize='1.5rem' />
                        <P2 color='white'>Dashboard</P2>
                    </SNavLink>
                    <SNavLink to='/upcoming-appointments' style={{ borderBottom: selected==='appointments' ? '3px solid white' : "" }}>
                        <FiCalendar fontSize='1.5rem' />
                        <P2 color='white'>Appointments</P2>
                    </SNavLink>
                    <SNavLink to="/messages" style={{ borderBottom: selected==='messages' ? '3px solid white' : "" }}>
                        <FiMail fontSize='1.5rem' />
                        <P2 color='white'>Messages</P2>
                    </SNavLink>
                    <SNavLink to="/profile" style={{ borderBottom: selected==='my-account' ? '3px solid white' : "" }}>
                        <FiBarChart2 fontSize='1.5rem' />
                        <P2 color='white'>My Account</P2>
                    </SNavLink>
                </NavbarLow>
                :
                <>
                    <NavbarLowMobile>
                        <NavItemContainerMobile className="ml-2">
                            <FiMenu
                                fontSize='1.5rem'
                                onClick={() => setOpenMenuDrawer(true)}
                            />
                        </NavItemContainerMobile>
                        <NavItemContainerMobile className='mr-2'>
                            {getSelectedTitle()}
                        </NavItemContainerMobile>
                    </NavbarLowMobile>

                    <Drawer
                        anchor='left'
                        open={openMenuDrawer}
                        onClose={() => setOpenMenuDrawer(false)}
                    >
                        <VBox
                            onClick={() => setOpenMenuDrawer(false)}
                            className='p-2'
                        >
                            <SNavLinkMobile className="p-1" to='/dashboard'>
                                <MdOutlineDashboard fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Dashboard</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/upcoming-appointments'>
                                <FiCalendar fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Appointments</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/messages'>
                                <FiMail fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Messages</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/profile'>
                                <FiBarChart2 fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">My Account</P1>
                            </SNavLinkMobile>
                            <Divider style={{ marginTop: 8 }} />
                            <A className="mt-1" href={process.env.REACT_APP_USER_MANUAL_URL} target='_blank'>
                                <P2>
                                    User Manual
                                </P2>
                            </A>
                        </VBox>
                    </Drawer>
                </>
            }
            
        </LowerNavContainer>
    )
}

const mapStateToProps1 = state => ({
    newMessage: state.chat.newMessage,
});

export const LowerNav = connect(mapStateToProps1, { setNewMessage })(_LowerNav);
/* ****************** End lower nav *************************** */

// const NavContainer = styled.div`
//     position: sticky;
//     position: -webkit-sticky;
//     top: 0px;
//     z-index: 1;
// `

// const Navbar = ({ selected, isAuthenticated, isVerified }) => {
//     return (
//         <NavContainer>
//             <UpperNav />
//             <LowerNav selected={selected} />
//         </NavContainer>
//     )
// }

// const mapStateToProps = state => ({
//     isAuthenticated: state.auth.isAuthenticated,
//     isVerified: state.auth.isVerified,
// });

// export default connect(mapStateToProps, {})(Navbar);