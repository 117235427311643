import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ReasonDlg = ({ open, setOpen, text }) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            {/* <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Reason</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line /> */}
                <P1 className="p-2">{text}</P1>
            <Line />
            <HBox align="center" justify="flex-end" className="px-2 py-1">
                <CloseBtn size="sm" onClick={handleClose}>Close</CloseBtn>
            </HBox>

        </Dialog>
    )
}

export default ReasonDlg;