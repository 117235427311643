import styled from "styled-components";
import { useState } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { useSnackbar } from 'notistack';

import { VBox, HBox } from "../../../components/Containers";
import { InputText } from "../../../components/InputText";
import { H1, H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import Alert from "../../../components/Alert";
import { verifyPasscode, logout } from "../../../services/actions/authAction";
import { getUUID } from "../../../utils";
import { localstorage } from "../../../config/strings";

const PLink = styled(P1)`
    text-decoration: underline;
    cursor: pointer;
`

const FormContainer = styled(VBox)`
    @media only screen and (max-width: 599px) { // xs or phone
        width: 85vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 899px) {  // sm
        width: 50vw;
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {  // md
        width: 40vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1535px) {  // lg
        width: 30vw;
    }
    @media only screen and (min-width: 1536px) {  // xl
        width: 30vw;
    }
`

const Form = ({networkError, verificationError, verifyPasscode, logout }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [code, setCode] = useState("");

    const resendPasscode = () => {
        enqueueSnackbar('Resending passcode...', { persist: true });
        axios({
            method: 'POST',
            url: 'auth/resend-passcode/',
            data: {
                device_id: getUUID(),
                login_with: localStorage.getItem(localstorage.loginwith),
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        }).then(res => {
            closeSnackbar();
            if (res.status === 200) {
                console.log('RESEND PASSCODE SUCCESS');
                enqueueSnackbar('Passcode sent.', {variant: 'success'});
            }
            else {
                enqueueSnackbar('Passcode send failed!', {variant: 'error'});
            }
        }).catch(error => {
            console.log('RESEND PASSCODE ERROR', error);
            closeSnackbar();
            enqueueSnackbar('Passcode send error!', {variant: 'error'});
        });
    }

    return (
        <FormContainer align="center">
            <H1 className="mb-1">Verify Passcode</H1>
            <P1 align="center" className="mb-2">A 6 digit passcode has been sent to your {localStorage.getItem(localstorage.loginwith)==='phone' ? 'phone number' : 'email'}. Please enter the passcode.</P1>
            <HBox>
                <InputText
                    placeholder="XXXXXX"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <Button
                    className="ml-1"
                    size="sm"
                    color="first"
                    onClick={() => {enqueueSnackbar('Verifying passcode...', { persist: true }); verifyPasscode(code)}}
                >
                    Submit
                </Button>
            </HBox>
            <HBox align="center" className="mt-2">
                <PLink color="first" onClick={resendPasscode}>Resend passcode?</PLink>
            </HBox>
            {verificationError && <>{closeSnackbar()} <Alert className='mt-2'>Verification error.</Alert></>}
            {networkError && <>{closeSnackbar()} <Alert className='mt-2'>Network error.</Alert></>}
        </FormContainer>
    )
}

const mapStateToProps = state => ({
    networkError: state.auth.networkError,
    verificationError: state.auth.verificationError,
});

export default connect(mapStateToProps, { verifyPasscode, logout })(Form);