export const specialtiesData = [
    "General Physician",
    // Medicine
    "Internal Medicine",
    "Cardiology",
    "Respiratory Medicine",
    "Neurology",
    "Nephrology",
    "Gastroenterology",
    "Hepatology",
    "Endocrinology",
    "Pediatrics",
    "Oncology",
    "Rheumatology",
    "Hematology",
    "Dermatology & Venereology",
    "Psychiatry",
    "Physical Medicine & Rehabilitation",
    "Aesthetic Dermatology",
    // Surgery
    "General Surgery",
    // "Cardiovascular Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Gynecology & Obstetrics",
    "ENT & Head Neck Surgery",
    "Ophthalmology",
    "Oral & Dental Surgery",
    "Urology",
    "Colorectal Surgery",
    "Pediatric Surgery",
    "Vascular Surgery",
    // "Hepatobiliary Surgery",
]

export const genderData = [
    'Female',
    'Male',
    'Other',
]

export const rxInst1 = [
    "1 + 0 + 0 Before meal",
    "0 + 1 + 0 Before meal",
    "0 + 0 + 1 Before meal",
    "1 + 1 + 1 Before meal",
    "1 + 1 + 0 Before meal",
    "0 + 1 + 1 Before meal",
    "1 + 0 + 1 Before meal",
    "1 + 0 + 0 After meal",
    "0 + 1 + 0 After meal",
    "0 + 0 + 1 After meal",
    "1 + 1 + 1 After meal",
    "1 + 1 + 0 After meal",
    "0 + 1 + 1 After meal",
    "1 + 0 + 1 After meal",
    "1 + 0 + 0 With meal",
    "0 + 1 + 0 With meal",
    "0 + 0 + 1 With meal",
    "1 + 1 + 1 With meal",
    "1 + 1 + 0 With meal",
    "0 + 1 + 1 With meal",
    "1 + 0 + 1 With meal",
]

export const rxInst2 = [
    "Continue",
    "Continue for 5 days",
    "Continue for 7 days",
    "Continue for 2 weeks",
    "Continue for 3 weeks",
    "Continue for 1 month",
    "Continue for 2 months",
    "Continue for 3 months",
]