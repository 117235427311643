import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { FiEdit3, FiCheck, FiX } from "react-icons/fi";
import { FaCamera } from "react-icons/fa";
import moment from "moment";
import { useSnackbar } from 'notistack';

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, CenterPanel, LeftPanel, RightPanel } from "../../components/Containers";
import { InputText } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { genderData } from "../../data";
import { getDate } from "../../utils";
import ChangePasswordDlg from "./components/ChangePasswordDlg";
import { localstorage } from "../../config/strings";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Password = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profile, setProfile] = useState({
        password_updated_on: null
    });
    const [changePassOpen, setChangePassOpen] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `doctor/doctor-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setProfile(response.data);
            } else {
                console.log('PROFILE FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PFOFILE FETCH ERROR', error);
        })
    }

    return (
        <>
        <LowerNav selected="my-account" />
        <HBox>
            {isMobile && <TopPanel justify='center' className='p-3'>
                <SLink to='/profile' className="m-0_5">
                    <Button size='sm' color='first' outlined>Profile</Button>
                </SLink>
                <SLink to='/fees' className="m-0_5">
                    <Button size='sm' color='first' outlined>Fees</Button>
                </SLink>
                <Button size='sm' color='first' className="m-0_5">Change Password</Button>
            </TopPanel>}
            {!isMobile && <LeftPanel>
                <SLink to="/profile">
                    <NavBtn size="sm" color="first" className="mt-8" outlined>
                        <div className="ml-1">Profile</div>
                    </NavBtn>
                </SLink>
                <SLink to="/fees">
                    <NavBtn size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Fees</div>
                    </NavBtn>
                </SLink>
                <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                    <div className="ml-1">Change Password</div>
                </NavBtnActive>
            </LeftPanel>}
            <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                <Scrollbars
                    style={{ height: 'calc(100vh - 150px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    <VBox className="p-3" style={{ width: '100%' }}>
                        <Paper className={isMobile ? 'p-2' : 'p-4'}>
                            <VBox>
                                <H3 color="first">Password</H3>
                                <P2>Do not share your password in public.</P2>

                                <table style={{ width: '100%' }} className="mt-2">
                                    <tr style={{ height: 30 }}>
                                        <td style={{ width: isMobile ? 120 : 200 }}><H5>********</H5></td>
                                        <td>{profile.password_updated_on ?
                                                <P1>Last updated on {moment(profile.password_updated_on).format("DD MMMM YYYY")}</P1>
                                                :
                                                <P1>Not updated after registration</P1>
                                            }
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            <EditIcon onClick={() => setChangePassOpen(true)} />
                                        </td>
                                    </tr>
                                </table>
                            </VBox>
                        </Paper>
                    </VBox>
                </Scrollbars>
            </CenterPanel>
        </HBox>
        {!isMobile && <Footer />}

        <ChangePasswordDlg
            open={changePassOpen}
            setOpen={setChangePassOpen}
            profile={profile}
            setProfile={setProfile}
        />
        </>
    );
}

export default Password;
