import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiCalendar } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtnActive, NavBtn } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { getDate } from "../../utils";
import AppointmentCard from "./components/AppointmentCard";
import { PatchLeft } from "../../components/Patches";
import { localstorage } from "../../config/strings";

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const DateContainer = styled(VBox)`
    width: max-content;
    align-self: center;
    background-color: white;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 1px ${colors.shadow};
`

const UpcomingAppointments = () => {
    const pageSize = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appointments, setAppointments] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [nums, setNums] = useState({ prev: null, upcoming: null });
    let prevDate = "";
    let currDate = "";

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, [])

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `doctor/appointment-list/`,
            params: {
                is_consulted: 1,
                offset: offset,
                limit: limit,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(localstorage.access)}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setAppointments(appointments.concat(response.data.appointments));
                setHasMore(response.data.has_more);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);

                setNums({ prev: response.data.prev_num,
                          upcoming: response.data.upcoming_num })
            } else {
                console.log('UPCOMING APPOINTMENTS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('UPCOMING APPOINTMENTS FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const setCurrDate = (date) => {
        currDate = date;
    }

    const updatePrevDate = () => {
        prevDate = currDate;
    }
    
    return (
        <div>
            <LowerNav selected="appointments" />
            <HBox>
                <PatchLeft />
                {isMobile && <TopPanel justify='center' className='p-3'>
                    <SLink to='/upcoming-appointments'>
                        <Button size='sm' color='first' className="m-0_5" outlined>Upcoming Appointments</Button>
                    </SLink>
                    <Button size='sm' color='first' className="m-0_5">Previous Appointments</Button>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <SLink to="/upcoming-appointments">
                        <NavBtn size="sm" color="first" className="mt-8" outlined>
                            <div className="ml-1">Upcoming appointments</div>
                            <div>{nums.upcoming}</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Previous appointments</div>
                        <div>{nums.prev}</div>
                    </NavBtnActive>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        style={{ height: 'calc(100vh - 150px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        {appointments.length ?
                            <VBox className="p-3">
                                {appointments.map((appointment, index) => (
                                    <>
                                    {setCurrDate(getDate(appointment.consult_slot?.date, 'long'))}
                                    {currDate !== prevDate && <DateContainer className='p-1 mb-2'>
                                        <H6>{currDate}</H6>
                                    </DateContainer>}
                                    <AppointmentCard
                                        isMobile={isMobile}
                                        id={appointment.id}
                                        patientUserId={appointment.patient_user_id}
                                        patientName={appointment.patient_name}
                                        ptId={appointment.patient_id}
                                        prescriptionId={appointment.prescription_id}
                                        date={appointment.consult_slot?.date}
                                        startTime={appointment.consult_slot?.start_time}
                                        endTime={appointment.consult_slot?.end_time}
                                        patientIsOnline={appointment.patient_is_online}
                                    />
                                    {updatePrevDate()}
                                    </>
                                ))}
                            </VBox>
                            :
                            !isLoading && <VBox align='center' justify='center' style={{ width: '100%', height: '100%' }}>
                                <img src='images/notfound.png' style={{ height: 80 }} />
                                <H3 className="mt-2">No Previous Appointments</H3>
                            </VBox>
                        }
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </HBox>
            {!isMobile && <Footer />}
        </div>
    )
}

export default UpcomingAppointments;