import styled from "styled-components";

import colors from "../config/colors";

export const OnlineChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${colors.green};
    padding: 2px 4px;
    border-radius: 10px;
`

export const OfflineChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${colors.lessDarkGrey};
    padding: 2px 4px;
    border-radius: 10px;
`